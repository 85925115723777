import InfiniteScroll from "react-infinite-scroll-component";
import { ITicket } from "../../constants/Interfaces/notesInterface";
import NoteCard from "./NoteCard";
import { sortBatchNotesByDate, sortByCreatedDateTime } from "utils/sort_data";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/index";

interface NoteContainerProps {
  noteList: ITicket[];
  hasMore: boolean;
  noDataFound?: boolean;
  handleNext: () => void;
  itemRefs?: any;
}

const NoteContainer = ({
  noteList,
  hasMore,
  handleNext,
  noDataFound = false,
  itemRefs,
}: NoteContainerProps) => {
  const [sortedNotesList, setSortedNotesList] = useState<ITicket[]>([]);

  const sortByCreatedTime = useSelector(
    (state: RootState) => state.ticketReducer.CREATEDTIME
  );

  sortBatchNotesByDate(noteList);

  // sortByCreatedTime = false
  useEffect(() => {
    if (sortByCreatedTime) {
      setSortedNotesList(sortByCreatedDateTime(noteList));
    } else {
      setSortedNotesList(sortBatchNotesByDate(noteList));
    }
  }, [sortByCreatedTime, noteList]);

  return (
    <InfiniteScroll
      scrollThreshold={0.8}
      loader={
        <div className=" h-[20vh] bg-transparent  flex items-center justify-center">
          <div className="loader"></div>
        </div>
      }
      dataLength={noteList?.length || 0}
      hasMore={hasMore}
      next={handleNext}
      scrollableTarget="scrollableDiv"
      endMessage={
        <div className=" h-[20vh] w-full bg-transparent  flex items-center justify-center">
          <p className=" text-[#9f9f9f]">
            {" "}
            {noteList?.length
              ? "You are all Caught up !"
              : "Oops ! No notes found"}
          </p>
        </div>
      }
    >
      <div className="flex flex-col gap-8 px-4">
        {sortedNotesList?.map((note: ITicket) => {
          return <NoteCard key={note?.id} notes={note} itemRefs={itemRefs} />;
        })}
      </div>
    </InfiniteScroll>
  );
};

export default NoteContainer;
