import * as RadixAccordion from "@radix-ui/react-accordion";
import { ITicket } from "constants/Interfaces/notesInterface";
import {
  Clock,
  Mail,
  PhoneIncoming,
  PhoneOutgoing,
  MessageSquareIcon,
  TicketIcon,
  ExternalLinkIcon,
  ChevronDown,
  UserPlus,
  Tag,
} from "lucide-react";
import moment from "moment-timezone";
import { Dispatch, SetStateAction, useState } from "react";
import { PSTtoLocal } from "utils/dateConverter";
import { dateDifference } from "utils/timeDifference";

const Accordion = ({
  note,
  setSelectedMergingNotes,
  defaultChecked,
}: {
  note: ITicket;
  setSelectedMergingNotes: Dispatch<SetStateAction<ITicket[]>>;
  defaultChecked?: boolean;
}) => {
  const [checked, setChecked] = useState<boolean>(false);

  const handleCheckboxChange = () => {
    setChecked((prev) => !prev);
    setSelectedMergingNotes((prev) => {
      if (checked && prev.includes(note)) {
        return prev.filter((item) => item.id !== note.id);
      } else {
        return [...prev, note];
      }
    });
  };

  return (
    <RadixAccordion.Item
      value={note.id}
      className={`mx-4 rounded-sm border-[1px] ${checked && "border-gray-800"}`}
    >
      <RadixAccordion.Header
        className={`flex flex-row items-center py-1 px-2 rounded-sm gap-2 hover:brightness-105 ${
          note?.status === "OPEN"
            ? "bg-[#f9d7bf] "
            : note?.status === "UNMANAGED"
            ? "bg-[#bfc0f9] "
            : "bg-[#edc7f7] "
        }`}
      >
        <input
          type="checkbox"
          checked={defaultChecked ? true : checked}
          onChange={defaultChecked ? undefined : handleCheckboxChange}
          className="bg-inherit hover:bg-black/10 flex h-[16px] w-[16px] items-center border-none justify-center"
        />
        <RadixAccordion.Trigger className="w-full flex flex-row justify-between items-center">
          <p className="flex flex-row gap-5 items-center">
            {note?.type === "CALL" ? (
              <PhoneIncoming size={18} className="stroke-[1.5]" />
            ) : note?.type === "CALL_OUT" ? (
              <PhoneOutgoing size={18} className="stroke-[1.5] " />
            ) : note?.type === "EMAIL" ? (
              <Mail size={18} className="stroke-[1.5] " />
            ) : note?.type === "SMS" ? (
              <MessageSquareIcon size={18} className="stroke-[1.5] " />
            ) : (
              <TicketIcon size={18} className="stroke-[1.5] " />
            )}
            <p className="flex flex-row items-center gap-2">
              <UserPlus size={16} />
              {note?.User
                ? note?.User?.first_name + " " + note?.User?.last_name
                : "Unassigned"}
            </p>
            <p className="flex flex-row items-center gap-2">
              <TicketIcon size={16} />
              {note?.status}
            </p>
            <p className="flex flex-row items-center gap-2">
              <Tag size={16} className="mt-0.5" />
              {note?.ticketNumber}
            </p>
            <p className="flex flex-row items-center gap-2">
              <Clock size={16} className="mt-0.5" />
              {note.updatedAt.slice(5, -7)}
            </p>
          </p>
          <ChevronDown size={16} aria-hidden />
        </RadixAccordion.Trigger>
      </RadixAccordion.Header>
      <RadixAccordion.Content className="px-[30px] bg-gray-100 rounded-b-md text-[15px]  data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden">
        <div className="flex flex-col gap-2 py-2">
          <div>
            <h2>Business: {note?.Business.name}</h2>
            <h2>From: {note?.ticketBy || "UNKNOWN"}</h2>
            <h2>
              Assigned to:{" "}
              <span>
                {note?.User?.first_name + " " + note?.User?.last_name}
              </span>
            </h2>
            <p>{note?.content}</p>
          </div>
          <div className="text-gray-800 flex flex-col gap-1">
            <p>Created at: {note.createdAt}</p>
            <div className="flex flex-row items-center gap-1">
              <Clock size={13} />
              <p>
                {`${note?.status || ""}
            ${
              note?.status === "UNMANAGED"
                ? `for ${moment(PSTtoLocal(note?.createdAt)).fromNow(true)}`
                : note?.status === "OPEN"
                ? `for ${moment(PSTtoLocal(note?.createdAt)).fromNow(true)}`
                : note?.status === "CLOSED"
                ? Boolean(note?.noteStatusUpdateAt)
                  ? `after ${dateDifference(
                      note?.createdAt,
                      note?.noteStatusUpdateAt
                    )}`
                  : ""
                : ""
            }
          `}
              </p>
            </div>
          </div>
          {/* Link */}
          <p className="flex flex-row items-center gap-1 text-primary hover:underline">
            <a href={`/app/note/${note.id}`} target="_blank" rel="noreferrer">
              View ticket
            </a>
            <ExternalLinkIcon size="13px" />
          </p>
        </div>
      </RadixAccordion.Content>
    </RadixAccordion.Item>
  );
};

export default Accordion;
