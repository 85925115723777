import { X } from "lucide-react";
import React, { FC } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

interface PlayerInterface {
    audio: string[];
}

const Player: FC<PlayerInterface> = (props) => {
    const { audio } = props;
    console.log(audio);
    return (
        <div className="w-full flex">
            {audio.map((elem: string, key: number) => (
                <AudioPlayer
                    layout="horizontal"
                    // customAdditionalControls={[]}
                    src={elem}
                    showJumpControls={false}
                    key={key}
                />
            ))}
        </div>
    );
};

export default Player;
