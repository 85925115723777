import {
  Contact,
  Cpu,
  Delete,
  Edit,
  Mail,
  MessageSquare,
  Phone,
  Plus,
  Search,
  Trash,
  UserCircle,
} from "lucide-react";
import React, { useEffect, useState } from "react";
import SwitchTabs from "./components/switch";
import API from "../../api";
import { useQuery } from "@tanstack/react-query";
import {
  BusinessMetaType,
  IBusiness,
  IBusinessDetails,
} from "constants/Interfaces/notesInterface";
import Modal from "components/modal";
import Mailing from "./mailAddressComponent";
import Accounts from "./accountComponent";
import Hardware from "./hardwareComponent";
import ContactSection from "./contactComponent";

const BusinessInfo = ({ business }: { business: IBusinessDetails }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  return (
    <aside
      className={` ${
        isOpen ? "w-full" : "w-0 hidden"
      } hidden lg:inline-block sticky top-[72px] max-w-[320px] h-[calc(100vh-72px)] bg-primary`}
    >
      <div className="flex flex-col gap-4 px-4 py-2">
        <div className="flex flex-col gap-4">
          <Mailing business={business} />
          <Accounts
            businessMeta={business?.BusinessMetas?.filter(
              (i) => i?.type === BusinessMetaType.ACCOUNT
            )}
          />
          <Hardware
            businessMeta={business?.BusinessMetas?.filter(
              (i) => i?.type === BusinessMetaType.HARDWARE
            )}
          />
          <ContactSection business={business} />
        </div>
      </div>
    </aside>
  );
};

export default BusinessInfo;
