import { ITicket } from "constants/Interfaces/notesInterface";
import { PSTtoLocal } from "./dateConverter";

export const sortByDate = (array: any) => {
  const sorter = (a: any, b: any) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  };
  return array ? [...array].sort(sorter) : [];
};

export const sortByCreatedDateTime = (array: ITicket[]) => {
  const sorter = (a: ITicket, b: ITicket) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  };
  return array ? [...array].sort(sorter) : [];
};

export const sortByUpdatedAt = (array: any) => {
  const sorted = array.sort((a: any, b: any) => {
    const dateA: any = new Date(a.updatedAt);
    const dateB: any = new Date(b.updatedAt);
    return dateB - dateA;
  });
  return sorted;
};

export const sortByDateReverse = (array: any) => {
  const sorter = (a: any, b: any) => {
    return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();
  };
  return array ? [...array].sort(sorter) : [];
};

export const sortUserList = (array: any, str: string) => {
  const sorter = (a: any, b: any) => {
    return a.email.search(str) - b.email.search(str);
  };
  return array ? [...array].sort(sorter) : [];
};

export const sortBusinessList = (array: any, str: string) => {
  const sorter = (a: any, b: any) => {
    return (
      a.name?.toLowerCase().search(str) - b.name?.toLowerCase().search(str)
    );
  };
  return array ? [...array].sort(sorter) : [];
};

export const sortBatchNotesByDate = (array: ITicket[]): ITicket[] => {
  return array?.sort((a: ITicket, b: ITicket) => {
    const dateA =
      a.type === "EMAIL" ? PSTtoLocal(a.updatedAt) : PSTtoLocal(a.createdAt);
    const dateB =
      b.type === "EMAIL" ? PSTtoLocal(b.updatedAt) : PSTtoLocal(b.createdAt);
    return dateB.getTime() - dateA.getTime();
  });
};
