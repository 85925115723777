import * as Switch from "@radix-ui/react-switch";

interface SwitchProps {
  text?: string;
  isDisabled?: boolean;
  isChecked?: boolean;
  onChange?: (value: any) => void;
}
const SwitchComponent = ({
  text,
  isDisabled = false,
  isChecked,
  onChange,
}: SwitchProps) => {
  // const [isChecked, setIsChecked] = useState(isDefaultChecked);

  return (
    <Switch.Root
      className="w-[28px] flex items-center h-[16px] bg-[#828282] rounded-full data-[state=checked]:bg-primary outline-none cursor-default"
      id="airplane-mode"
      onCheckedChange={onChange}
      checked={isChecked}
      // defaultChecked={isDefaultChecked}
      disabled={isDisabled}
      aria-checked={isChecked}
    >
      <Switch.Thumb className=" z-auto relative w-[12px] h-[12px] bg-white rounded-full transition-transform duration-100 translate-x-[3px] will-change-transform data-[state=checked]:translate-x-[13px]" />
    </Switch.Root>
  );
};

export default SwitchComponent;
