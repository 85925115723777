import SelectComponent from "components/select";
import { PhoneCall, PhoneMissed, PhoneOff } from "lucide-react";
import moment from "moment";
import { PSTtoLocal } from "../../../utils/dateConverter";
import { useAppSelector } from "store";
import { extractUsername } from "../../../utils/extractUsername";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import API from "../../../api";
import PopoverComponent from "components/popover";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const statusSelect = [
  { id: "active", label: "ACTIVE" },
  { id: "inactive", label: "INACTIVE" },
  { id: "brb", label: "BRB" },
  { id: "busy", label: "BUSY" },
  { id: "inmeeting", label: "IN MEETING" },
  { id: "onbreak", label: "ON BREAK" },
  { id: "onlunch", label: "LUNCH" },
];

const StatusCard = ({ user }: any) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { user_role, currentUser,isSalesUser } = useAppSelector(
    (state) => state.authUserReducer
  );

  const { data: openNotesCount } = useQuery({
    queryKey: ["openNotesCount", user?.id],
    queryFn: async () => {
      try {
        if(user?.id){
          const res = await API.get(`notes/user/count/${user?.id}`, {
            params: {
              channel: isSalesUser ? "sales" : "",
            },
          });
          return Number(res.data.count);
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
  });

  const updateUserStatus = async (data: any) => {
    const response = await API.post("/changeUserCurrentStatus", data);
    return response.data;
  };
  const fetchUserPhoneStatusList = async (userId: any) => {
    const response = await API.get(
      `/getUserStatusList?userId=${userId}&type=PHONE`
    );
    return response.data;
  };
  const fetchUserStatusList = async (userId: any) => {
    const response = await API.get(
      `/getUserStatusList?userId=${userId}&type=USER`
    );
    return response.data;
  };

  const {
    data: phoneStatusList,
    refetch: userPhoneStatusRefetch,
    isError,
    isLoading:isPhoneStatusLoading
  } = useQuery(
    ["phoneStatusList", user.userId],
    async () => fetchUserPhoneStatusList(user.userId),
    {
      enabled: false,
    }
  );
  const { data: userStatusList, refetch: userStatusRefetch,isLoading:isUserStatusLoading } = useQuery(
    ["userStatusList", user.userId],
    () => fetchUserStatusList(user.userId),
    {
      enabled: false,
    }
  );

  const userStatusMutation = useMutation(updateUserStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries(["userDetails"]);
    },
  });

  const handleUserStatusChange = async (newStatus: any, userId: any) => {
    try {
      await userStatusMutation.mutateAsync({
        status: newStatus,
        userID: userId,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const handleShowTicketsClick = () => {
    navigate(`/app/assignedTickets/${user.id}?&tab=TIMELINE`);
  };

  return (
    <div
      key={user.ROWID}
      className="flex-col hover:bg-[#f5f5f5] gap-2 px-[16px] py-[8px] border-b border-[#f2f4f7]"
    >
      <div className="flex justify-between items-center text-[#2a2a2a]">
        <PopoverComponent
          position="left"
          triggerButton={
            <p className="cursor-pointer text-[14px]" onClick={() => userStatusRefetch()}>
              {user.firstName} {user.lastName} {!!openNotesCount&&`(${openNotesCount})`}
            </p>
          }
        >
          <div className=" flex flex-col gap-2 min-h-[240px] max-h-[70vh] overflow-y-auto min-w-[280px]">
            <div className="flex justify-between sticky top-0 items-center bg-primary px-4 py-2">
              <div className="flex flex-col ">
                <p className=" font-semibold  text-white">
                  {user.firstName} {user.lastName}
                </p>
                <p className="text-[14px] text-[#c2c2c2]">
                  {` ${moment()
                    .tz("America/Los_Angeles")
                    .startOf("day")
                    .format("YYYY/MM/DD")}`}
                </p>
              </div>

              <button
                onClick={() => handleShowTicketsClick()}
                className="px-2 py-1 h-fit text-[13px] border rounded-sm border-white text-white"
              >
                Show tickets
              </button>
            </div>
            {isError && <p>Some Error Occured !!</p>}
            
            {isUserStatusLoading ? (
              <p className=" px-4 py-8 flex  justify-center  text-[#9f9f9f]">
                Loading ...
              </p>
            ): userStatusList?.length > 0 ? (
          userStatusList?.map((userStatus: any,index:number) => {
                return (
                  <div
                    key={userStatus?.UserStatus?.ROWID}
                    className="flex items-center gap-4 px-4 py-2 border-b border-[#E6EBEF]"
                  >
                    <div className="flex gap-6 text-[#2a2a2a]">
                      <div className="flex gap-2">
                        <p className="text-[16px]  ">
                          {` ${moment(
                            PSTtoLocal(userStatus?.UserStatus?.startTime)
                          ).format("hh:mm A")}`}
                        </p>
                        <p>-</p>
                        <p className="text-[16px] text-[#9f9f9f] ">
                          {index===0? "Now":`${moment(
                            PSTtoLocal(userStatus?.UserStatus?.endTime)
                          ).format("hh:mm A")}`}
                        </p>
                      </div>
                    </div>
                    <p
                      className={`${
                        userStatus?.UserStatus?.status === "active"
                          ? " text-green-500"
                          : user.supportStatus === "busy" ||
                            "lunch" ||
                            "onbreak" ||
                            "inmeeting" ||
                            "brb"
                          ? "text-red-500"
                          : "text-[#c2c2c2]"
                      }`}
                    >
                      {userStatus?.UserStatus?.status}
                    </p>
                  </div>
                );
              })
            ) : (
              <p className=" px-4 py-8 flex  justify-center  text-[#9f9f9f]">
                StatusList Not Found !!
              </p>
            )}
          </div>
        </PopoverComponent>
        
        <p
          className={`text-[12px] ${
            user.supportStatus === "active"
              ? " text-green-500"
              : user.supportStatus === "busy" ||
                user.supportStatus === "lunch" ||
                user.supportStatus === "onbreak" ||
                user.supportStatus === "inmeeting" ||
                user.supportStatus === "brb"
              ? "text-red-500"
              : "text-[#c2c2c2]"
          }`}
        >
          {statusSelect.find((item) => item.id === user?.supportStatus)
            ?.label || "Clocked out"}
          {user?.userStatusModified &&
            ` (${moment(PSTtoLocal(user?.userStatusModified)).fromNow(true)})`}
        </p>
        
      </div>
      <div className="flex justify-between">
      <PopoverComponent
        position="left"
        triggerButton={
          <div
            onClick={() => userPhoneStatusRefetch()}
            className={`flex cursor-pointer w-fit items-center gap-2 ${
              user.status === "active"
                ? " text-green-500"
                : user.status === "busy"
                ? "text-red-500"
                : "text-[#c2c2c2]"
            }`}
          >
            {user.status === "active" ? (
              <PhoneCall size={13} />
            ) : user.status === "busy" ? (
              <PhoneMissed size={13} />
            ) : (
              <PhoneOff size={13} />
            )}

            {/* <p className="text-[13px]">{user.supportStatus}</p> */}
            <p className="text-[12px]">
              {`${user?.status ?? "clocked out"} `}
              {user?.phoneStatusModified &&
                `(${moment(PSTtoLocal(user?.phoneStatusModified)).fromNow(
                  true
                )})`}
            </p>
          </div>
        }
      >
        <div className=" flex flex-col gap-2 min-h-[240px] max-h-[70vh] overflow-y-auto  min-w-[280px]">
          <div className="flex flex-col sticky top-0 bg-primary px-4 py-2">
            <p className=" font-semibold  text-white">
              {user.firstName} {user.lastName} (Phone)
            </p>
            <p className="text-[14px] text-[#c2c2c2]">
              {` ${moment()
                .tz("America/Los_Angeles")
                .startOf("day")
                .format("YYYY/MM/DD")}`}
            </p>
          </div>
          {isPhoneStatusLoading?(
            <p className=" px-4 flex py-6 justify-center text-[#9f9f9f]">
             Loading ...
            </p>
          ):phoneStatusList?.length > 0 ? (
            phoneStatusList?.map((userStatus: any,index:number) => {
              return (
                <div
                  key={userStatus.UserStatus.ROWID}
                  className="flex gap-2 items-center px-4 py-2 border-b border-[#E6EBEF]"
                >
                  <div className="flex gap-6">
                    <div className="flex gap-2">
                      <p className="text-[16px] text-[#2a2a2a]">
                        {` ${moment(
                          PSTtoLocal(userStatus.UserStatus.startTime)
                        ).format("hh:mm A")}`}
                      </p>
                      <p>-</p>
                      <p className="text-[16px] text-[#9f9f9f]">
                        {index===0? "Now":`${moment(
                          PSTtoLocal(userStatus.UserStatus.endTime)
                        ).format("hh:mm A")}`}
                      </p>
                    </div>
                    <p
                      className={`${
                        userStatus?.UserStatus?.status === "active"
                          ? " text-green-500"
                          : user.supportStatus === "busy" ||
                            "lunch" ||
                            "onbreak" ||
                            "inmeeting" ||
                            "brb"
                          ? "text-red-500"
                          : "text-[#c2c2c2]"
                      }`}
                    >
                      {userStatus.UserStatus.status}
                    </p>
                  </div>
                </div>
              );
            })
          ) : (
            <p className=" px-4 flex py-6 justify-center text-[#9f9f9f]">
              StatusList Not Found !!
            </p>
          )}
        </div>
      </PopoverComponent>
      {(user_role.includes("Admin") ||
          user.userId === extractUsername(currentUser.email)) && (
          <div className="text-[12px] ">
            <SelectComponent
              placeholder="Set Status"
              value={
                statusSelect.find((item) => item.id === user?.supportStatus)
                  ?.id || "inactive"
                // user?.status
              }
              options={statusSelect}
              onValueChange={(e: any) =>
                handleUserStatusChange(e.id, user?.userId)
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default StatusCard;
