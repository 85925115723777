import React from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider,
  TooltipArrow,
} from "@radix-ui/react-tooltip";

function TooltipComponent({ children, content }:{children:React.ReactNode,content:React.ReactNode}) {
  return (
    <TooltipProvider delayDuration={200}>
      <Tooltip>
        <TooltipTrigger>{children}</TooltipTrigger>
        <TooltipContent
          side="right"
          className="bg-white border z-50 p-2 rounded-md min-w-[160px]"
        >
          <TooltipArrow className=" fill-[#9f9f9f]" />

          {content}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

export default TooltipComponent;
