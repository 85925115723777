import Modal from "components/modal";
import { PhoneCall, PhoneOutgoing } from "lucide-react";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { handleCallAction } from "store/notes/call_handling_action";

interface callModalProps {
  isOpen: boolean;
  onClose: () => void;
  callingTo: string;
  phoneNumber: string;
  businessID: string;
  contactID: string;
  noteID?: string;
  businessName: string;
}

const CallModal = ({
  isOpen,
  onClose,
  businessID,
  contactID,
  callingTo,
  phoneNumber,
  noteID,
  businessName,
  ...rest
}: callModalProps) => {
  const [isCalling, setCalling] = useState<boolean>(false);
  const values = {
    businessID,
    contactID,
    content: `Call back to  ${phoneNumber}`,
    contact_phone: phoneNumber,
    noteID,
  };
  const handleCall = async () => {
    if (!phoneNumber) {
      toast.error("Phone number not valid !!");
      return;
    }
    try {
      const response = await handleCallAction(values);
      if(response){
        setCalling(true);
        setTimeout(()=>{
          onClose();
        },2000)
      }
      console.log(response);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} {...rest}>
      {isCalling ? (
        <div className="flex flex-col py-2 px-4 gap-4 items-center">
          <div className="w-64 h-40 flex justify-center items-end ">
            <PhoneCall className="stroke-primary fill-primary h-32 w-32 animate-bounce" />
          </div>
          <div className="flex flex-col items-center">
            <h4 className="font-medium text-gray-500  text-[14px]">Calling</h4>
            <h3 className="font-medium text-[20px]">
              {callingTo ?? "Unknown"}
            </h3>
          <p>Phone Number {phoneNumber ?? "Unknown"}</p>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center gap-6 px-4 py-2">
          <h3 className="font-medium text-[20px]">Are you sure you want to make this call?</h3>
          <div className="flex flex-col gap-1 items-center">
           <PhoneOutgoing className="stroke-primary fill-primary h-16 w-16" />
            <h4 className=" font-medium text-[20px]">
              {businessName ?? "Unknown"}
            </h4>
            <h3 className="font-medium text-[16px] text-[#9f9f9f]">
              {callingTo ?? "Unknown"}
            </h3>
            <p className="font-medium text-[20px]">
              {phoneNumber ?? "No contact number"}
            </p>
          </div>
          <div className="flex gap-6 w-full mt-4 px-6 justify-center">
            <button
              onClick={() => handleCall()}
              className="min-w-[64px] w-full rounded-md font-medium px-2 py-1  bg-purple-950  text-white "
            >
              Call
            </button>
            <button
              onClick={onClose}
              className="min-w-[64px] w-full rounded-md font-medium px-2 py-1 border border-purple-950  text-purple-950 "
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default CallModal;
