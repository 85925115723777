import React, { lazy, Suspense } from "react";
import AddNewTicketModal from "../AddNewTicketModal";
import { Building } from "lucide-react";
import SwitchComponent from "../components/switch";
import { useDispatch, useSelector } from "react-redux";
import { toggleSortByCreatedTime } from "store/home/ticketSlice";
import { RootState } from "store/index";
const SwitchTabs = lazy(() => import("../components/switchtabs"));

const ContainerHeader = ({
  tabStatus,
  headerName,
  tabList,
  showAddNoteModel = false,
  onTabChange,
}: any) => {
  const isSortedByCreatedTime = useSelector(
    (state: RootState) => state.ticketReducer.CREATEDTIME
  );
  const dispatch = useDispatch();

  return (
    <div className="w-full sticky top-0 flex flex-col md:flex-row md:gap-8 bg-white md:items-center px-4  justify-between pt-4">
      {!!headerName && (
        <div className="flex items-center gap-2 text-primary text-[24px] font-bold">
          <Building size={24} className="stroke-white fill-primary" />
          <h5> {headerName}</h5>
        </div>
      )}

      <div className="flex gap-4">
        <Suspense fallback={<></>}>
          <SwitchTabs
            value={tabStatus}
            tablist={tabList}
            onChange={onTabChange}
          />
        </Suspense>

        <div className="flex items-center gap-2 text-primary">
          <p className="text-sm">MODIFIEDTIME</p>
          <SwitchComponent
            isChecked={isSortedByCreatedTime}
            onChange={() => {
              dispatch(toggleSortByCreatedTime());
            }}
          />
          <p className="text-sm">CREATEDTIME</p>
        </div>
      </div>

      {showAddNoteModel && (
        <div className="min-w-fit hidden lg:inline-block">
          <AddNewTicketModal />
        </div>
      )}
    </div>
  );
};

export default ContainerHeader;
