import Homepage from "pages/Homepage";
import BusinessDetail from "pages/BusinessDetailPage";
import { RouteInterface } from "./";
import TablePage from "pages/TablePage";
import SearchPage from "pages/SearchPage";
import VideoCall from "pages/videoCallPage";
import VideocallFeedback from "components/videocallFeedback";
import AssignedTickets from "pages/AssignedTickets";
import NoteDetails from "pages/NoteDetails";
import MyTickets from "pages/MyTickets";
// import { $FIXME } from "../../../../constantTypes";
// import UserFeed from "../views/userfeed";

export const landingRoute: RouteInterface[] = [
  {
    path: "/app",
    element: Homepage,
    isAuth: true,
  },
  {
    path: "/app/business/:businessUUID",
    element: BusinessDetail,
    isAuth: true,
  },
  {
    path: "/app/video/:noteUUID",
    element: VideoCall,
    isAuth: false,
  },
  {
    path: "/app/video/callEnd",
    element: VideocallFeedback,
    isAuth: false,
  },
  {
    path: "/app/table",
    element: TablePage,
    isAuth: true,
  },
  {
    path: "/app/search",
    element: SearchPage,
    isAuth: true,
  },
  {
    path: "/app/assignedTickets/:userUUID",
    element: AssignedTickets,
    isAuth: true,
  },
  {
    path: "/app/myTickets",
    element: MyTickets,
    isAuth: true,
  },
  {
    path: "/app/note/:noteUUID",
    element: NoteDetails,
    isAuth: true,
  },
];

export const salesLandingRoute: RouteInterface[] = [
  {
    path: "/app/",
    element: Homepage,
  },
  {
    path: "/app/b/:businessUUID",
    element: BusinessDetail,
  },
  {
    path: "/app/table",
    element: TablePage,
    isAuth: true,
  },
  {
    path: "/app/search",
    element: SearchPage,
    isAuth: true,
  },
  //   {
  //     path: "/app/embed/:noteUUID",
  //     element: Note,
  //   },
  //   {
  //     path: "/app/note/:noteUUID",
  //     element: Note,
  //   },
  //   {
  //     path: "/app/user",
  //     element: User,
  //     isRole: "Admin",
  //   },

  //   {
  //     path: "/app/table",
  //     element: Table,
  //   },
  //   {
  //     path: "/app/new-ticket",
  //     element: NewTicket,
  //   },
  //   {
  //     path: "/app/search",
  //     element: Search,
  //   },
  //   {
  //     path: "/app/search/:content",
  //     element: Search,
  //   },
];
