import LayoutWrapper from "components/layout";
import Main from "components/main";
import SIPUsers from "features/SIPUsers";
import NoteContainer from "features/noteContainer";
import RecentNotes from "features/recentNotes";
import React, { Suspense, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import API from "api/index";
import { useAppSelector } from "store/index";
import ContainerHeader from "features/noteContainer/containerHeader/ContainerHeader";
import { useQueryParams } from "hooks/useQueryParams";
import { TabStatus } from "store/tab/tabSlice";
import { ArrowLeft, ArrowRight, Search } from "lucide-react";
import SwitchTabs from "features/noteContainer/components/switchtabs";
import { ITicket } from "constants/Interfaces/notesInterface";
import { tab } from "@testing-library/user-event/dist/tab";
import { preProcessFile } from "typescript";

const SwitchTabContent = [
  {
    value: TabStatus.TIMELINE,
    name: "All",
  },
  {
    value: TabStatus.OPEN,
    name: "Open Tickets",
  },
  {
    value: TabStatus.CLOSED,
    name: "Closed Tickets",
  },
];

const AssignedTickets = () => {
  //   const { businessUUID } = useParams();
  const { getQueryParam, setQueryParam } = useQueryParams();
  const [data, setData] = useState<ITicket[]>([]);
  const [pages, setPages] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const params = useParams();
  const userID = params.userUUID;
  const tabStatus = getQueryParam("tab") || "";

  const {
    data: noteAssignedData,
    isLoading: isNoteLoading,
    isError,
  } = useQuery({
    queryKey: ["notesAssigned", pages, userID, tabStatus],
    queryFn: async () => {
      const res = await API.get(`notes/user/${userID}`, {
        params: {
          page: pages,
          status: tabStatus === "TIMELINE" ? undefined : tabStatus,
        },
      });

      return res.data;
    },
  });

  useEffect(() => {
    setHasMore(!isError);
  }, [isError]);

  useEffect(() => {
    // setData([...data, ...noteAssignedData]);
    if (!noteAssignedData) return;
    if (pages === 1) {
      setData(noteAssignedData);
    } else {
      setData((previousData) => [...previousData, ...noteAssignedData]);
    }
  }, [noteAssignedData]);

  useEffect(() => {
    setData([]);
  }, [userID]);

  const { data: UserDetail } = useQuery({
    queryKey: ["userDetail", userID],
    queryFn: async () => {
      try {
        const res = await API.get(`findUser/${userID}`);
        return res.data;
      } catch (e) {
        console.log(e);
      }
    },
  });

  useEffect(() => {
    setHasMore(true);
    setData([]);
  }, [tabStatus]);

  const handleNext = () => {
    if (isNoteLoading) return;
    setPages((prevPage) => prevPage + 1);
  };
  const filteredData = useMemo(() => {
    return data?.filter((item: ITicket) => item.status === tabStatus);
  }, [data, tabStatus]);

  return (
    <LayoutWrapper>
      <RecentNotes className="hidden lg:inline-block" />
      <Main>
        <div className="z-20 sticky top-0 flex flex-col md:flex-row md:gap-8 bg-white md:items-center justify-between pt-2 px-4">
          <div className="flex gap-4 items-center">
            <button onClick={() => window.history.back()}>
              <ArrowLeft size={24} />
            </button>
            <div className="flex items-center gap-2 text-primary text-[18px] md:text-[24px] font-bold">
              <h5>
                Notes Assigned{" "}
                {UserDetail &&
                  ` to ${UserDetail?.first_name} ${
                    UserDetail?.last_name || ""
                  }`}
              </h5>
            </div>
          </div>
          <Suspense fallback={<></>}>
            <SwitchTabs
              value={tabStatus}
              tablist={SwitchTabContent}
              onChange={(e: any) => {
                setPages(1);
                setQueryParam("tab", e);
              }}
            />
          </Suspense>
        </div>
        <NoteContainer
          hasMore={hasMore}
          handleNext={handleNext}
          noteList={tabStatus === "TIMELINE" ? data : filteredData}
          noDataFound={!data?.length}
        />
      </Main>
      <SIPUsers className="hidden lg:inline-block" />
    </LayoutWrapper>
  );
};

export default AssignedTickets;
