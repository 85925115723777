import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { X } from "lucide-react";

interface DialogBoxProps {
  title?: string;
  isOpen:boolean;
  onClose: () => void
  showCloseButton?: boolean
  trigger: React.ReactNode;
  children?: React.ReactNode;
}
const DialogBox = ({ trigger, children, title,isOpen,showCloseButton=false,onClose }: DialogBoxProps) => {
  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Trigger>{trigger}</Dialog.Trigger>
      <Dialog.Overlay onClick={onClose} className="w-full fixed inset-0 bg-black opacity-50" />
      <div className="w-full max-w-[720px] absolute top-4/5 left-1/2 -translate-x-1/2 -translate-y-1/5 z-50">
        <Dialog.Content className=" text-black  rounded-lg  bg-white">
          <Dialog.Title className="text-[20px] font-medium border-b px-4 py-4 border-[#E6EBEF]">
            <div className="flex justify-between w-full">
              <div>{title}</div>
              {showCloseButton&&  <Dialog.Close asChild onClick={onClose} className="cursor-pointer">
                <X size={20} />
              </Dialog.Close>}
             
            </div>
          </Dialog.Title>
          <div className="flex flex-col px-4 py-4">{children}</div>
        </Dialog.Content>
      </div>
    </Dialog.Root>
  );
};

export default DialogBox;
