import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "store";
import NotificationSound from "assets/notification_sound.mp3";
import { checkAuthentication } from "./store/auth_user/authUserAction";
import { useNavigate } from "react-router-dom";
import Pusher from "pusher-js";
import { PUSHER_KEY, salesDomain } from "constants/constant";
import { useQueryClient } from "@tanstack/react-query";
import Routes from "./route";
import { landingRoute, salesLandingRoute } from "route/landingRoute";
import { fetchUserList } from "store/home/homeAction";
import { getAllBusinessList } from "store/business/businessAction";

declare interface GZAnalyticsType {
  init(systemId: string, userId: string): void;
  setUser(userId: string | number): void;
  customLog(data: any): void;
  setBusinessData(businessData: any): void;
  setCustomConstants(constantData: any): void;
}
declare global {
  interface Window {
    catalyst: any;
    GZAnalytics: GZAnalyticsType;
  }
}
function App() {
  const query = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const audioPlayer: any = useRef(null);
  const isPublic = window.location.href.indexOf("/video") > -1;
  // console.log({ isPublic });
  const { currentUser, isSalesUser } = useAppSelector(
    (state) => state.authUserReducer
  );
  useEffect(() => {
    if (!isPublic) {
      dispatch(checkAuthentication());
      dispatch(fetchUserList());
      dispatch(getAllBusinessList(isSalesUser));
    }
  }, []);

  useEffect(() => {
    const pusher = new Pusher(PUSHER_KEY, {
      cluster: "ap2",
    });
    const channel = pusher.subscribe("notes");
    channel.bind("notes-event", function (response: any) {
      setTimeout(function () {
        try {
          console.log(response);
          if (
            response.type === "NoteUpdate" ||
            response.type === "NoteDelete"
          ) {
            query.invalidateQueries(["batchNotes"]);
            query.invalidateQueries(["businessBatchNotes"]);
            query.invalidateQueries(["noteDetails"]);
            query.invalidateQueries(["notesAssigned"]);
            query.invalidateQueries(["myNotes"]);
            query.invalidateQueries(["searchNotes"]);
            query.invalidateQueries(["noteCount"]);
          } else if (response.type === "NoteAdded") {
            if (
              !(
                (isSalesUser && response.channel === "SALES") ||
                (!isSalesUser && response.channel !== "SALES")
              )
            )
              return;
            query.invalidateQueries(["batchNotes"]);
            query.invalidateQueries(["businessBatchNotes"]);
            query.invalidateQueries(["noteDetails"]);
            query.invalidateQueries(["notesAssigned"]);
            query.invalidateQueries(["myNotes"]);
            query.invalidateQueries(["searchNotes"]);
            query.invalidateQueries(["noteCount"]);
            if (!isSalesUser) playAudio();
          } else if (response.type === "UserStatus") {
            query.invalidateQueries(["userDetails"]);
          } else if (response.type === "RecentNote") {
            query.invalidateQueries(["recentNotes"]);
          } else if (response.type === "Testing") {
            playAudio();
          }
        } catch {
          console.log(response);
        }
      }, Math.random() * 3000);
    });

    const navigationPath = window.location.pathname;
    if (navigationPath && navigationPath.includes("index.html"))
      navigate("/app");
    // else navigate("/app/");
    return () => {
      pusher.disconnect();
    };
  }, [isSalesUser]);

  useEffect(() => {
    if (salesDomain) document.title = "Growthzilla Nurture";
    const navigationPath = window.location.pathname;
    if (
      navigationPath &&
      (navigationPath.includes("index.html") || navigationPath === "/")
    )
      navigate("/app");
  }, []);

  function playAudio() {
    audioPlayer.current.play();
  }
  return (
    <>
      <Routes routeItems={!isSalesUser ? landingRoute : salesLandingRoute} />
      <audio ref={audioPlayer} src={NotificationSound} />
    </>
  );
}

export default App;
