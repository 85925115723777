import moment from "moment-timezone";

export function dateDifference(date1:any, date2:any) {
    const start = moment(date1, 'YYYY-MM-DD HH:mm:ss');
    const end = moment(date2, 'YYYY-MM-DD HH:mm:ss');

   
    const differenceInDays = end.diff(start, 'days');
    if (differenceInDays > 0) {
        return `${differenceInDays} days`;
    }

    const differenceInHours = end.diff(start, 'hours');
    if (differenceInHours > 0) {
        return `${differenceInHours} hours`;
    }

    const differenceInMinutes = end.diff(start, 'minutes');
    return `${differenceInMinutes} minutes`;

}