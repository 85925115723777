import { useNavigate, useLocation } from 'react-router-dom';

export function useQueryParams<T extends Record<string, any>>() {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  function getQueryParam<K extends keyof T>(name: K): T[K] | null {
    const value = searchParams.get(name as string);
    if (value === null) {
      return null;
    }
    // You might need to cast the string value to the expected type.
    // This example assumes all values can be correctly represented as strings.
    return value as unknown as T[K];
  }

  function setQueryParam<K extends keyof T>(name: K, value: T[K]): void {
    // Convert value to string if necessary
    const stringValue = String(value);
    searchParams.set(name as string, stringValue);
    navigate({ search: searchParams.toString() });
  }

  return {
    getQueryParam,
    setQueryParam
  };
}