import React from "react";
import { Routes as Switch, Route } from "react-router-dom";
import { useAppSelector } from "../store";

export interface RouteInterface {
  path: string;
  element: React.FC;
  isAuth?: boolean;
  isRole?: string;
}

export interface RoutePropsInterface {
  routeItems: RouteInterface[];
}

//import this to add routes.
const Routes: React.FC<RoutePropsInterface> = (props) => {
  const { routeItems } = props;
  const email = localStorage.getItem("email_id");
  const { currentUser, authLoading } = useAppSelector(
    (store) => store.authUserReducer
  );
  return (
    <React.Suspense fallback={<div className="loader"></div>}>
      <Switch>
        {routeItems.map((route: RouteInterface) => (
          <Route
            key={route.path}
            path={`${route.path}`}
            element={
              route.isAuth ? (
                email && currentUser?.id ? (
                  <route.element />
                ) : authLoading ? (
                  <div className="w-full flex h-[90vh] justify-center items-center">
                    <div className="loader"></div>
                  </div>
                ) : (
                  <div className="h-[20vh] w-full bg-transparent  flex items-center justify-center">
                    <div>Not Authorized</div>
                  </div>
                )
              ) : (
                <route.element />
              )
            }
          />
        ))}
      </Switch>
    </React.Suspense>
  );
};

export default Routes;
