import LayoutWrapper from "components/layout";
import Main from "components/main";
import SIPUsers from "features/SIPUsers";
import NoteContainer from "features/noteContainer";
import RecentNotes from "features/recentNotes";
import Sidebar from "components/sidebar";
import React, { useMemo, useState } from "react";
import BusinessInfo from "features/businessInfo";
import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import API from "api/index";
import { useAppSelector } from "store/index";
import ContainerHeader from "features/noteContainer/containerHeader/ContainerHeader";
import { useQueryParams } from "hooks/useQueryParams";
import { TabStatus } from "store/tab/tabSlice";


const SwitchTabContent = [
  {
    value: TabStatus.TIMELINE,
    name: "All",
  },
  {
    value: TabStatus.OPEN,
    name: "Open Tickets",
  },
  {
    value: TabStatus.UNMANAGED,
    name: "Unmanaged Tickets ",
  },
  {
    value: TabStatus.CLOSED,
    name: "Closed Tickets",
  },
];

const BusinessDetailPage = () => {
  const { businessUUID } = useParams();
  const { getQueryParam, setQueryParam } = useQueryParams();
  // const [tabStatus, setTabStatus] = useState(getQueryParam('tab'));
  let tabStatus = getQueryParam('tab');
  console.log(tabStatus);
  const [businessData, setBusinessData] = useState<any>([]);
  const [notesData, setnotesData] = useState<any>([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const { isSalesUser, currentUser } = useAppSelector(
    (state) => state.authUserReducer
  );
  const { data } = useQuery({
    queryKey: ["businessBatchNotes", page],
    queryFn: async () => {
      try{
        const res = await API.get(`/business/${businessUUID}`, {
          params: {
            page,
            channel: isSalesUser ? "SALES" : "",
          },
        });
        // return res.data;
        if (res.status === 200) {
          const { Notes,...rest } = res.data;
          setBusinessData(rest);
          setnotesData(Notes);
          res?.data?.Notes.length === notesData.length && setHasMore(false)
          return [...notesData,...res?.data?.Notes];
        }
      }catch(e){
        console.log(e);
        setHasMore(false);
      }
    },
  });
  const handleNext = () => {
    // console.log("handleNext");
    setPage((prevPage) => prevPage + 1);
  };
  const filteredData = useMemo(() => {
    return notesData?.filter((item: any) => item.status === tabStatus);
  }, [notesData, tabStatus]);

  console.log(businessData);
  console.log(data);
  return (
    <LayoutWrapper>
        <BusinessInfo
          business={businessData}
        />
      <Main>
        <ContainerHeader
        tabStatus = {tabStatus}
        tabList = {SwitchTabContent}
        onTabChange={
          (e:any)=> setQueryParam('tab',e)
        }
        headerName={businessData?.name} />
        <NoteContainer
          hasMore={hasMore}
          handleNext={handleNext}
          noteList={tabStatus === "TIMELINE" ? notesData : filteredData}
        />
      </Main>
      <SIPUsers className="hidden lg:inline-block" />
    </LayoutWrapper>
  );
};

export default BusinessDetailPage;
