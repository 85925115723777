import { BUSINESS_ADDED } from "../home/homeReducer";

export const GET_ALL_BUSINESS = "GET_ALL_BUSINESS";
export const GET_BUSINESS_LIST = "GET_BUSINESS_LIST";

interface BusinessReducerInterface {
  business_info: any;
  businessList: any;
}
const initialState: BusinessReducerInterface = {
  business_info: [],
  businessList: [],
};

const businessReducer = (state = initialState, action: any) => {
  const { type, business_info, businessList, id, name } = action;
  switch (type) {
    case GET_ALL_BUSINESS:
      return {
        ...state,
        business_info: business_info,
      };
    case GET_BUSINESS_LIST:
      return {
        ...state,
        businessList: businessList,
      };
    case BUSINESS_ADDED:
      return {
        ...state,
        businessList: businessList?.map((x: any) => x.id).includes(id)
          ? businessList
          : [...(businessList || []), { id, name }],
      };
    default:
      return state;
  }
};

export default businessReducer;
