import React, { useState, useRef, useEffect } from "react";
import { Pause, Play, SkipBack, SkipForward, X } from "lucide-react";
import Modal from "components/modal";
import API from "api/index";

interface VideoPlayInterface {
  onClose: () => void;
  isOpen: boolean;
  videoLink: string;
  videoID: string;
  commentType: string;
}

interface CanvasData {
  [key: number]: any[][];
}

function findKeyShorterOrEqual(
  canvasData: CanvasData,
  maxLength: number
): number | null {
  const keys = Object.keys(canvasData).map(Number);
  const validKeys = keys.filter((key) => key <= maxLength);
  const maxKey = Math.max(...validKeys);
  return maxKey !== -Infinity ? maxKey : null;
}

const VideoPlayerModal: React.FC<VideoPlayInterface> = (props) => {
  const { onClose, isOpen, videoLink, videoID, commentType } = props; // const dispatch = useDispatch();
  const vidRef = useRef<HTMLVideoElement>(null);

  const [canvasSize, setCanvasSize] = useState({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
  });
  const [isBuffering, setIsBuffering] = useState(false);
  const displayCanvasRef = React.useRef<HTMLCanvasElement>(null);

  // Setting the example data as initial data
  const [canvasData, setCanvasData] = useState<CanvasData>({
    0: [],
  });

  const handleBufferStart = () => {
    setIsBuffering(true);
  };

  const handleBufferEnd = () => {
    setIsBuffering(false);
  };
  const [currentDuration, setCurrentDuration] = useState(0);

  const canvasAndVideoPositionSync = () => {
    const videoElement = document
      .querySelector("#recordingVideoElement")!
      .getBoundingClientRect();
    // setVideoPosition({ x: videoElement.x, y: videoElement.y });
    setCanvasSize({
      width: videoElement.width,
      height: videoElement.height,
      top: videoElement.top,
      left: videoElement.left,
    });
  };

  useEffect(() => {
    let displayCanvas: HTMLCanvasElement,
      displayContext: CanvasRenderingContext2D | null;

    function redrawCanvas(circles: any[]) {
      if (displayCanvasRef.current) {
        displayCanvas = displayCanvasRef.current;
        displayContext = displayCanvas.getContext("2d");

        displayContext!.clearRect(
          0,
          0,
          displayCanvas.width,
          displayCanvas.height
        );

        circles.forEach((circle) => {
          const translatedX = circle[0] * displayCanvas.width;
          const translatedY = circle[1] * displayCanvas.height;
          const translatedRadius =
            circle[2] * Math.min(displayCanvas.height, displayCanvas.width);

          displayContext!.beginPath();
          displayContext!.arc(
            translatedX,
            translatedY,
            translatedRadius,
            0,
            2 * Math.PI
          );

          displayContext!.strokeStyle = circle[4];
          displayContext!.lineWidth = 5;
          displayContext!.stroke();
          displayContext!.closePath();

          displayContext!.fillStyle = circle.color;
          displayContext!.font = "25px Arial";
          displayContext!.textAlign = "center";
          displayContext!.textBaseline = "middle";
          displayContext!.fillText(
            circle[3].toString(),
            translatedX,
            translatedY
          );
        });

        canvasAndVideoPositionSync();
      }
    }

    const intervalId = setInterval(() => {
      // 1. Set the current duration to accurate time
      if (vidRef.current) {
        setCurrentDuration(Math.floor(vidRef.current.currentTime));
      }

      // 2. Set the canvas elements if present
      const currVideo = vidRef.current;
      if (currVideo) {
        const currTime = Math.floor(vidRef.current!.currentTime);

        const currentUsedKey = findKeyShorterOrEqual(canvasData, currTime) ?? 0;

        if (canvasData.hasOwnProperty(currentUsedKey)) {
          redrawCanvas(canvasData[currentUsedKey]);
        }
      }

      // console.log("Canvas Data is: ", canvasData);
    }, 1000); // Update every second

    return () => {
      clearInterval(intervalId); // Clean up the interval on unmount
    };
  }, [canvasData]);

  useEffect(() => {
    if (commentType === "VIDEO") {
      // const meetingID = "6b4d68b7-158d-4a85-b503-3879055c3b2c";
      const res = API.get(`notes/canvas-recordings?meetingID=${videoID}`);
      res.then((r: any) => {
        const cData = r.data;
        // Setting 0 to clear so that reversing from point with canvas drawing also clears the screen
        cData[0] = [];
        setCanvasData(cData);
        // console.log("RES IS: ", r);
        // console.log("DATA IS", canvasData);
      });
    }
  }, []);

  const handleCloseModal = () => {
    onClose();
    setCurrentDuration(0);
    console.log("Video closed!", isOpen);
    console.log("Video closed!", videoLink);
  };

  const handlePlayVideo = () => {
    const currVideo = vidRef.current!;
    currVideo.paused ? currVideo.play() : currVideo.pause();
    canvasAndVideoPositionSync();
  };

  const handleRewindBack = () => {
    const currVideo = vidRef.current!;
    currVideo.currentTime -= 10;
  };
  const handleRewindForwad = () => {
    const currVideo = vidRef.current!;
    currVideo.currentTime += 10;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} showCloseButton>
      <>
        {" "}
        <div className="relative">
          <div
            className="absolute z-10 aspect-auto top-0 left-0"
            id="canvas-cover"
            onClick={canvasAndVideoPositionSync}
          >
            <canvas
              ref={displayCanvasRef}
              width={canvasSize.width < 1 ? 10 : canvasSize.width}
              height={canvasSize.height}
            />
          </div>
          <div>
            <div className="relative">
              <div className="video-cover">
                {videoLink ? (
                  <video
                    ref={vidRef}
                    width="100%"
                    id="recordingVideoElement"
                    onWaiting={handleBufferStart}
                    onPlaying={handleBufferEnd}
                  >
                    <source src={videoLink} type="video/mp4" />
                    Your browser does not support HTML video.
                  </video>
                ) : (
                  <>Recording is not ready yet, try again in few minutes!</>
                )}
              </div>
              {/* <div className=" absolute text-white top-[16px] left-[16px]">
                {currentDuration}s / {vidRef.current?.duration}
              </div> */}
              {/* Display the loading animation when it's buffering and not paused */}
              {isBuffering && !vidRef.current?.paused ? (
                <div className="absolute bottom-[50%] right-[50%]">
                  <div className="loader"></div>
                </div>
              ) : vidRef.current?.paused ? (
                <div className="absolute text-white bottom-[50%] right-[48%] z-20">
                  <Play className="w-10 h-10" onClick={handlePlayVideo} />
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className="flex justify-center">
              <div className="flex flex-col gap-4 px-6 py-4 justify-center items-center">
                <div className="flex">
                {currentDuration}s / {vidRef.current?.duration}
                </div>
                <div className="flex gap-6">
                  <button
                    onClick={handlePlayVideo}
                    className="rounded-md border w-10 h-10 flex justify-center items-center border-black"
                  >
                    {vidRef.current?.paused ? <Play /> : <Pause />}
                  </button>
                  <button
                    onClick={handleRewindBack}
                    className="rounded-md border w-10 h-10 flex justify-center items-center border-black"
                  >
                    <SkipBack />
                  </button>
                  <button
                    onClick={handleRewindForwad}
                    className="rounded-md border w-10 h-10 flex justify-center items-center border-black"
                  >
                    <SkipForward />
                  </button>
                  <button
                    onClick={handleCloseModal}
                    className="rounded-md border w-10 h-10 flex justify-center items-center border-black"
                  >
                    <X />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default VideoPlayerModal;
