import { Paperclip } from "lucide-react";
import React, { useState } from "react";
import Select from "react-select";
import DialogBox from "components/dialog";
import { useAppDispatch, useAppSelector } from "../../store";
import { IBusiness } from "../../constants/Interfaces/notesInterface";
import { useFormik } from "formik";
import { NoteStatus } from "../../constants/noteStatus";
import { addNote } from "store/notes/note_action";
import toast from "react-hot-toast";

export const AddNewTicketModal = () => {
  const[isModalOpen, setIsModalOpen] = useState(false);
  const { businessList } = useAppSelector((state) => state.businessReducer);
  const { isSalesUser } = useAppSelector((state) => state.authUserReducer);
  const dispatch = useAppDispatch();
  const { userList } = useAppSelector((state) => state.homeReducer);
  const businessOptionsList = businessList.map((business: IBusiness) => {
    return {
      value: business.id,
      label: business.name,
    };
  });
  const userSelectOptions = userList?.map((user: any) => ({
    label: `${user.first_name} ${user.last_name}`,
    value: user.id,
  }));
  const formik = useFormik({
    initialValues: {
      content: "",
      userUUID: "",
      businessUUID: "",
      attachments: "",
      note_status: NoteStatus.UNMANAGED,
      channel: isSalesUser ? "SALES" : undefined,
    },
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      try{
        const response = await addNote(values);
        console.log(response);
      }catch(e){
        toast.error( `Error:${e}`)
      }
        resetForm();
        setIsModalOpen(false);
      // navigate("/app/");
    },
  });
  return (
    <DialogBox
    isOpen={isModalOpen}
    showCloseButton
    onClose={()=>setIsModalOpen(false)}
      title="Create New Ticket"
      trigger={
        <div onClick={()=>setIsModalOpen(true)} className="w-fit rounded-md font-medium px-2 py-1 border border-purple-950  text-purple-950 ">
          + New Ticket
        </div>
      }
    >
      <div className=" flex flex-col gap-4">
        <div className="flex flex-col gap-2 w-full">
          <label
            htmlFor="businessName"
            className="text-[14px] text-[#828282] font-normal"
          >
            Business Name <span className="text-red-500">*</span>
          </label>
          <Select
            name="businessName"
            id="businessName"
            options={businessOptionsList}
            onChange={(e: any) => formik.setFieldValue("businessUUID", e.value)}
          />
        </div>

        <div className="flex gap-4">
          <div className="flex flex-col gap-2 w-full">
            <label
              htmlFor="userAssign"
              className="text-[14px] text-[#828282] font-normal"
            >
              Assigned To <span className="text-red-500">*</span>
            </label>
            <Select
              name="userAssign"
              id="userAssign"
              options={userSelectOptions}
              onChange={(e: any) => formik.setFieldValue("userUUID", e.value)}
              // onChange={(e)=>console.log(e)}
            ></Select>
          </div>
          {/* <div className="flex flex-col gap-2 w-full">
            <label
              htmlFor=""
              className="text-[14px] text-[#828282] font-normal"
            >
              Status <span className="text-red-500">*</span>
            </label>
            <Select
              isSearchable={false}
              options={[
                { value: "OPEN", label: "OPEN" },
                { value: "CLOSED", label: "CLOSED" },
                { value: "UNMANAGED", label: "UNMANAGED" },
              ]}
              onChange={(e)=>console.log(e)}
            />
          </div> */}
        </div>
        <div className="flex flex-col gap-2">
          <label
            htmlFor="content"
            className="text-[14px] text-[#828282] font-normal"
          >
            Ticket Description <span className="text-red-500">*</span>
          </label>
          <textarea
            className="border focus:outline-blue-500 border-[#E6EBEF] rounded-md px-4 py-2"
            name="content"
            id="content"
            onChange={(e)=>formik.setFieldValue('content',e.target.value)}
            cols={30}
            required
            rows={5}
          ></textarea>
        </div>

        <div className="flex flex-col gap-2 ">
          <label
            htmlFor="attachments"
            className="text-[14px] text-[#828282] font-normal"
          >
            Attachments
          </label>
          <div className="w-full flex  items-center bg-white cursor-pointer border-dashed border border-[#2a2a2a] px-4 py-2 rounded-md">
            <Paperclip className="text-[#828282]" size={20} />

            <input
              id="attachments"
              name="attachments"
              onChange={(e: any) => {
                formik.setFieldValue("attachments", e.currentTarget.files);
              }}
              type="file"
              multiple={true}
              className=" file:bg-transparent file:border-none file:text-[#828282]"
            />
          </div>
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            onClick={()=>formik.handleSubmit()} 
            className="bg-primary text-white px-4 py-2 rounded-md"
          >
            Create Ticket
          </button>
        </div>
      </div>
    </DialogBox>
  );
};

export default AddNewTicketModal;
