import React from "react";

const Main = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      id="scrollableDiv"
      className="w-full bg-[#e7eaec] flex gap-4 flex-col h-[calc(100vh-72px)] overflow-auto"
    >
      {children}
    </div>
  );
};

export default Main;
