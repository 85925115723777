import { createSlice } from "@reduxjs/toolkit";

export const TabStatus = {
  TIMELINE: "TIMELINE",
  OPEN: "OPEN",
  CLOSED: "CLOSED",
  UNMANAGED: "UNMANAGED",
};

export interface ITabStatus {
  tabStatus: "TIMELINE" | "OPEN" | "CLOSED" | "UNMANAGED";
}

const initialState: ITabStatus = {
  tabStatus: "TIMELINE",
};

const TabSlice = createSlice({
  name: "tabs",
  initialState,
  reducers: {
    setTabStatus: (state, action) => {
      state.tabStatus = action.payload;
    },
  },
});

export const { setTabStatus } = TabSlice.actions;

export default TabSlice.reducer;
