import { useState } from "react";
import cn from 'classnames'
import { Check, Trash, X } from "lucide-react";

interface IinlineDelete {
    onAccept: () => void;
    title?: string;
    className?: string;
  
}

export const InlineDelete = ({
    onAccept,
    title,
    className,
  }: IinlineDelete) => {
    const [enableDelete, setEnableDelete] = useState(false);
    return (
      <div className={cn(className, "flex gap-2")}>
        {enableDelete ? (
          <div className="flex gap-2 justify-center items-center">
            <p className="text-[12px]">Sure?</p>
            <button
              onClick={()=>{
                onAccept();
                setEnableDelete(false);
  
              }}
              className="flex justify-center rounded-sm w-4 h-4 items-center gap-2 border border-black/40 hover:bg-red-500 hover:text-white"
            >
              <Check size={16} />
            </button>
            <button
              onClick={() => setEnableDelete(false)}
              className="flex justify-center rounded-sm w-4 h-4 items-center gap-2 border border-black/40 hover:bg-green-500 hover:text-white"
            >
              <X size={16} />
            </button>
          </div>
        ) : (
          <button
            onClick={() => setEnableDelete(true)}
            className="flex items-center gap-2 hover:text-red-500"
          >
            <Trash size={16} />
           {!!title &&<p className="inline-block">{title}</p>} 
          </button>
        )}
      </div>
    );
  };

  export default InlineDelete;