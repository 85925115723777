import React, { useEffect, useMemo } from "react";
import * as RadixSelect from "@radix-ui/react-select";
import { ChevronsUpDown } from "lucide-react";

interface SelectOption {
  id: string;
  label: string;
}

interface SelectProps {
  options: SelectOption[];
  value?: string;
  searchable?: boolean;
  placeholder: string;
  onValueChange?: (option: SelectOption) => void;
  confirmTicketClose?: boolean;
}
const SelectComponent = ({
  options,
  value,
  placeholder,
  searchable,
  onValueChange,
  confirmTicketClose,
}: SelectProps) => {
  const [localValue, setLocalValue] = React.useState(value);
  const [searchQuery, setSearchQuery] = React.useState("");
  const filteredData = useMemo(() => {
    if (searchQuery.trim() === "") {
      return options;
    }
    return options.filter((option) =>
      option.label.toLowerCase().includes(searchQuery.trim().toLowerCase())
    );
  }, [options, searchQuery]);
  const noResultsFound = searchQuery.trim() !== "" && filteredData.length === 0;

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    confirmTicketClose && setLocalValue("CLOSED");
  }, [confirmTicketClose]);

  return (
    <RadixSelect.Root
      value={localValue}
      onValueChange={(v) => {
        if (v !== "CLOSED") {
          setLocalValue(v);
        }
        const selectedOption = options.find((option) => option.id === v);
        if (onValueChange && selectedOption) {
          onValueChange(selectedOption);
        }
      }}
    >
      <RadixSelect.Trigger
        className="flex lg:gap-2 items-center px-2 focus:outline-none"
        onBlur={() => setSearchQuery("")}
      >
        <RadixSelect.Value placeholder={placeholder}>
          <div className="hidden lg:inline-block">
            {options.find((o) => o.id === localValue)?.label ?? placeholder}
          </div>
        </RadixSelect.Value>
        <RadixSelect.Icon>
          <div className="w-3 h-3">
            <ChevronsUpDown size={14} />
          </div>
        </RadixSelect.Icon>
      </RadixSelect.Trigger>
      <RadixSelect.Content position="popper" className="relative top-4">
        <RadixSelect.Viewport className=" bg-white w-fit max-h-[240px] overflow-y-scroll  border rounded-md border-[#E6EBEF] ">
          {/* <div className=" bg-white  border rounded-md border-[#E6EBEF] absolute top-4 left-0"> */}
          {searchable && (
            <div className=" sticky top-0 px-4 py-2 bg-white">
              <input
                type="text"
                placeholder="Search..."
                className="px-2 py-1 focus:outline-blue-500  w-fit"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          )}
          {noResultsFound ? (
            <RadixSelect.Item
              value=""
              disabled
              className="flex items-center justify-center px-4 py-4 border-b border-[#f2f4f7]"
            >
              No search results found
            </RadixSelect.Item>
          ) : (
            filteredData.map((option) => (
              <RadixSelect.Item
                key={option.id}
                value={option.id}
                className={`text-black  flex focus:outline-none cursor-pointer  border-b border-[#f2f4f7] ${
                  localValue === option.id
                    ? "bg-primary text-white hover:text-black"
                    : "bg-transparent"
                } hover:bg-[#E6EBEF] px-4 py-2`}
              >
                {option.label}
              </RadixSelect.Item>
            ))
          )}
          {/* </div> */}
        </RadixSelect.Viewport>
      </RadixSelect.Content>
    </RadixSelect.Root>
  );
};

export default SelectComponent;
