import React from "react";
import * as Popover from "@radix-ui/react-popover";

const PopoverComponent = ({
  children,
  triggerButton,
  position,
  onClose,
  onOpenChange,
  style,
  open,
  title,
  hasCrossButton = false,
}: {
  children: React.ReactNode;
  triggerButton: React.ReactNode;
  onClose?: () => void;
  hasCrossButton?: boolean;
  style?: React.CSSProperties;
  title?: string;
  open?: boolean;
  onOpenChange?: () => void;
  position?: "right" | "left" | "top" | "bottom";
}) => {
  return (
    <Popover.Root open={open ?? undefined}>
      <Popover.Trigger asChild autoFocus={false}>
        {triggerButton}
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          onPointerDownOutside={() => onClose && onClose()}
          style={style}
          // style={{position:'fixed',left:'-615px' ,top:'-10px'}}
          side={position }
          className="bg-white z-50 outline-none data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade  mb-2 max-h-[95vh]  overflow-y-auto rounded bg-gray0 shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2)] will-change-[transform,opacity] scrollbar-none "
          sideOffset={5}
          align="start"
        >
          {title && <div className="p-[12px] text-[#343C46]">{title}</div>}
          <div>{children}</div>
          {hasCrossButton && (
            <Popover.Close
              className="absolute right-[13px] top-[13px]"
              aria-label="Close"
            >
              <button onClick={() => onClose && onClose()}>x</button>
            </Popover.Close>
          )}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
export default PopoverComponent;
