import { WebRTC } from "@signalwire/js";

async function getCameras() {
    try {
        await WebRTC.getDevices();
        // console.log(devices);
        const cams = await WebRTC.getCameraDevices();
        // const cams = await WebRTC.getCameraDevicesWithPermissions();
        console.log("Cameras", cams);
        return cams;
    } catch (e) {
        console.log("Error occured", e);
        return [];
    }
}
export default getCameras;