export enum NoteType {
  SMS = "SMS",
  CALL = "CALL",
  CALL_OUT = "CALL_OUT",
  OTHERS = "OTHERS",
}

export enum NoteStatus {
  UNMANAGED = "UNMANAGED",
  OPENED = "OPEN",
  CLOSED = "CLOSED",
  // PENDING = "PENDING",
  // BLOCKED = "BLOCKED",
  // IN_DEV = "IN_DEV",
}
