import { ITicket } from "constants/Interfaces/notesInterface";
import {
  Clock,
  Mail,
  MessageSquareIcon,
  PhoneIncoming,
  PhoneOutgoing,
  Tag,
  TicketIcon,
  UserPlus,
} from "lucide-react";
import moment from "moment-timezone";
import { PSTtoLocal } from "utils/dateConverter";
import { dateDifference } from "utils/timeDifference";

const NotesOverviewItemContent = ({ note }: { note: ITicket }) => {
  return (
    <div className="mx-4 border-[1px] rounded-xl relative z-[1000] w-[450px] text-sm">
      <div
        className={`flex flex-row items-center py-1 px-2 rounded-t-xl gap-2 ${
          note?.status === "OPEN"
            ? "bg-[#f9d7bf] "
            : note?.status === "UNMANAGED"
            ? "bg-[#bfc0f9] "
            : "bg-[#edc7f7] "
        }`}
      >
        <div className="w-full flex flex-row justify-between items-center">
          <p className="flex flex-row gap-5 items-center">
            {note?.type === "CALL" ? (
              <PhoneIncoming size={18} className="stroke-[1.5]" />
            ) : note?.type === "CALL_OUT" ? (
              <PhoneOutgoing size={18} className="stroke-[1.5] " />
            ) : note?.type === "EMAIL" ? (
              <Mail size={18} className="stroke-[1.5] " />
            ) : note?.type === "SMS" ? (
              <MessageSquareIcon size={18} className="stroke-[1.5] " />
            ) : (
              <TicketIcon size={18} className="stroke-[1.5] " />
            )}
            <p className="flex flex-row items-center gap-2">
              <UserPlus size={16} />
              {note?.User
                ? note?.User?.first_name + " " + note?.User?.last_name
                : "Unassigned"}
            </p>
            <p className="flex flex-row items-center gap-2">
              <TicketIcon size={16} />
              {note?.status}
            </p>
            <p className="flex flex-row items-center gap-2">
              <Tag size={16} className="mt-0.5" />
              {note?.ticketNumber}
            </p>
          </p>
        </div>
      </div>
      <div className="px-[30px] bg-gray-100 rounded-b-md overflow-hidden">
        <div className="flex flex-col gap-2 py-2">
          <div>
            <h2>Business: {note?.Business.name}</h2>
            <h2>From: {note?.ticketBy || "UNKNOWN"}</h2>
            <h2>
              Assigned to:{" "}
              <span>
                {note?.User?.first_name + " " + note?.User?.last_name}
              </span>
            </h2>
            <p>{note?.content}</p>
          </div>
          <div className="text-gray-800 flex flex-col gap-1">
            <p>Created at: {note.createdAt}</p>
            <div className="flex flex-row items-center gap-1">
              <Clock size={13} />
              <p>
                {`${note?.status || ""}
            ${
              note?.status === "UNMANAGED"
                ? `for ${moment(
                    PSTtoLocal(note?.createdAt),
                    "YYYY-MM-DD HH:mms:ss"
                  ).fromNow(true)}`
                : note?.status === "OPEN"
                ? `for ${moment(PSTtoLocal(note?.createdAt)).fromNow(true)}`
                : note?.status === "CLOSED"
                ? Boolean(note?.noteStatusUpdateAt)
                  ? `after ${dateDifference(
                      note?.createdAt,
                      note?.noteStatusUpdateAt
                    )}`
                  : ""
                : ""
            }
          `}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotesOverviewItemContent;
