import React from "react";
import * as Tabs from "@radix-ui/react-tabs";

interface ItabList {
  name: string;
  value: string;
}
interface SwitchTabContentProps {
  tablist: ItabList[];
  value: string;
  onChange: (e: string) => void;
}

export const SwitchTabs = ({
  value,
  tablist,
  onChange,
}: SwitchTabContentProps) => {
  return (
    // <div>asdf</div>
    <Tabs.Root className="w-full  flex  flex-col " defaultValue={value}>
      <Tabs.List className="flex px-[16px] py-[8px] bg-purple-800 ">
        {tablist.map((tab, index) => {
          return (
            <Tabs.Trigger
              key={index}
              onClick={() => onChange && onChange(tab.value)}
              value={tab.value}
              className={`font-medium py-2 px-4  data-[state=active]:text-white text-purple-300 data-[state=active]:bg-primary rounded-md `}
            >
              {tab.name}
            </Tabs.Trigger>
          );
        })}
      </Tabs.List>

      {/* <div>
        {tablist.map((tab, index) => {
          return (
            <Tabs.Content
              key={index}
              className="p-4 text-white"
              value={tab.name}
            >
              {tab.content}
            </Tabs.Content>
          );
        })}
      </div> */}
    </Tabs.Root>
  );
};

export default SwitchTabs;
