import { ITicket } from "constants/Interfaces/notesInterface";
import {
  PhoneIncoming,
  PhoneOutgoing,
  Mail,
  MessageSquareIcon,
  TicketIcon,
} from "lucide-react";

const ICON_SIZE = 11;
interface NotesOverviewItemType {
  note: ITicket;
  numberOfItems: number;
}

const NotesOverviewItem = ({ note, numberOfItems }: NotesOverviewItemType) => {
  let dynamicHeight = 0.5;
  if (note.type === "CALL" || note.type === "CALL_OUT") {
    const results = note.content.match(/\b\d+\b/g);
    const callDuration = results?.[results.length - 1];

    if (callDuration && parseInt(callDuration) > 60) {
      // dynamicHeight = (parseInt(callDuration) * 20 * 0.1) / numberOfItems;
      dynamicHeight =
        dynamicHeight + 0.25 * Math.ceil(parseInt(callDuration) / 60);
    }
  }
  return (
    <div
      style={{ height: `${Math.max(0.75, dynamicHeight)}rem` }}
      className={`flex items-center justify-start w-20 ${
        note.status === "OPEN"
          ? "bg-[#f9d7bf]"
          : note.status === "UNMANAGED"
          ? "bg-[#bfc0f9]"
          : "bg-[#edc7f7]"
      } px-2`}
    >
      <div
        className={`w-full flex justify-between items-center gap-2 text-[${ICON_SIZE}px] text-gray-800`}
      >
        <p className="cursor-default">
          {note.User
            ? `${note.User?.first_name?.[0] || ""}${
                note.User?.last_name?.[0] || ""
              }`
            : "na"}
        </p>
        <span>
          {note?.type === "CALL" ? (
            <PhoneIncoming size={ICON_SIZE} className="stroke-[1.5]" />
          ) : note?.type === "CALL_OUT" ? (
            <PhoneOutgoing size={ICON_SIZE} className="stroke-[1.5] " />
          ) : note?.type === "EMAIL" ? (
            <Mail size={ICON_SIZE} className="stroke-[1.5] " />
          ) : note?.type === "SMS" ? (
            <MessageSquareIcon size={ICON_SIZE} className="stroke-[1.5] " />
          ) : (
            <TicketIcon size={ICON_SIZE} className="stroke-[1.5] " />
          )}
        </span>
      </div>
      {/* <p className="truncate text-sm">{note.Business.name}</p> */}
    </div>
  );
};

export default NotesOverviewItem;
