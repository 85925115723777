import { useQueryClient } from "@tanstack/react-query";
import Modal from "components/modal";
import { useFormik } from "formik";
import { Edit, Mail, Plus } from "lucide-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { updateBusinessAddress } from "store/business/businessAction";
import { useAppDispatch } from "store/index";

export const Mailing = ({ business }: any) => {
  const [isOpenModal, setModelOpen] = useState({ open: false, edit: false });
  const address = `${business?.address || ''} ${business?.state ||''} ${business?.city ||''} ${business?.zipcode || ''}`;
  console.log(address);
  const query = useQueryClient();
  const dispatch = useAppDispatch();
  const { businessUUID } = useParams();
  const formik = useFormik({
    initialValues: {
      address:  "",
      state: "",
      zipcode: "",
      city: "",
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        await dispatch(updateBusinessAddress(businessUUID || "", values));
      } catch (e) {
        console.log(e);
      } finally {
        resetForm();
        setModelOpen({ ...isOpenModal, open: false });
        setModelOpen({ ...isOpenModal, edit: false });
        query.invalidateQueries(["businessBatchNotes"]);
      }
    },
  });

  console.log(formik.values);
  return (
    <>
      <div className="flex justify-between  text-purple-500">
        <div className="flex items-center gap-2">
          <Mail size={20} />
          <h5 className=""> Mailing Address</h5>
        </div>
        {Boolean(
          business?.address ||
            business?.city ||
            business?.state ||
            business?.zipcode
        ) ? (
          <Edit
            size={18}
            className=" cursor-pointer"
            onClick={() => setModelOpen({ ...isOpenModal, edit: true })}
          />
        ) : (
          <Plus
            size={18}
            className=" cursor-pointer"
            onClick={() => setModelOpen({ ...isOpenModal, open: true })}
          />
        )}
      </div>
      <div className="flex flex-col text-[14px]">
        <h5 className="text-white">
          {!!(
            business?.address ||
              business?.city ||
              business?.state ||
              business?.zipcode
          )
            ? address
            : "Not found !"}
        </h5>
      </div>
      <Modal
        isOpen={isOpenModal.open}
        showCloseButton
        title="Add Mailing Address"
        onClose={() => setModelOpen({ ...isOpenModal, open: false })}
      >
        <div className="flex flex-col gap-2 px-6">
          <div className="flex gap-1 flex-col">
            <label className="text-[14px] font-medium" htmlFor="key">
              Address
            </label>
            <input
              className="py-1 px-2 border rounded-sm focus:outline-blue-500/50"
              id="key"
              type="text"
              required
              onChange={(e: any) =>formik.setFieldValue("address", e.target.value)}
            />
          </div>
          <div className="flex gap-1 flex-col">
            <label className="text-[14px] font-medium" htmlFor="key">
              State
            </label>
            <input
              className="py-1 px-2 border rounded-sm focus:outline-blue-500/50"
              id="key"
              type="text"
              required
              onChange={(e: any) =>formik.setFieldValue("state", e.target.value)}
            />
          </div>
          <div className="flex gap-1 flex-col">
            <label className="text-[14px] font-medium" htmlFor="key">
              City
            </label>
            <input
              className="py-1 px-2 border rounded-sm focus:outline-blue-500/50"
              id="key"
              type="text"
              required
              onChange={(e: any) =>formik.setFieldValue("city", e.target.value)}
            />
          </div>
          <div className="flex gap-1 flex-col">
            <label className="text-[14px] font-medium" htmlFor="value">
              Zipcode
            </label>
            <input
              className="py-1 px-2 border rounded-sm focus:outline-blue-500/50"
              id="value"
              type="text"
              required
              onChange={(e: any) =>formik.setFieldValue("zipcode", e.target.value)}
            />
          </div>
          <button
            onClick={()=>formik.handleSubmit()}
            // onClick={() => console.log("cliked")}
            className="bg-primary text-white px-4 py-2 mt-2 rounded-sm"
          >
            Save
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={isOpenModal.edit}
        showCloseButton
        title="Change Mailing Address"
        onClose={() => setModelOpen({ ...isOpenModal, edit: false })}
      >
        <div className="flex flex-col gap-2 px-6">
          <div className="flex gap-1 flex-col">
            <label className="text-[14px] font-medium" htmlFor="key">
              Address
            </label>
            <input
              className="py-1 px-2 border rounded-sm focus:outline-blue-500/50"
              id="key"
              type="text"
              onChange={(e: any) =>formik.setFieldValue("address", e.target.value)}
            />
          </div>
          <div className="flex gap-1 flex-col">
            <label className="text-[14px] font-medium" htmlFor="key">
              State
            </label>
            <input
              className="py-1 px-2 border rounded-sm focus:outline-blue-500/50"
              id="key"
              type="text"
           
              onChange={(e: any) =>formik.setFieldValue("state", e.target.value)}
            />
          </div>
          <div className="flex gap-1 flex-col">
            <label className="text-[14px] font-medium" htmlFor="key">
              City
            </label>
            <input
              className="py-1 px-2 border rounded-sm focus:outline-blue-500/50"
              id="key"
              type="text"
             
              onChange={(e: any) =>formik.setFieldValue("city", e.target.value)}

            />
          </div>
          <div className="flex gap-1 flex-col">
            <label className="text-[14px] font-medium" htmlFor="value">
              Zipcode
            </label>
            <input
              className="py-1 px-2 border rounded-sm focus:outline-blue-500/50"
              id="value"
              type="text"
              onChange={(e: any) =>formik.setFieldValue("zipcode", e.target.value)}
            />
          </div>
          <button
            onClick={()=>formik.handleSubmit()}
            // onClick={() => console.log("cliked")}
            className="bg-primary text-white px-4 py-2 mt-2 rounded-sm"
          >
            Save
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Mailing;
