import { PUBLIC_URL } from "../../constants/constant";
import {
  GET_AUTH_USER,
  GET_SIP_USERS,
  GET_USER_STATUS,
  SET_AUTH_LOADING,
} from "./authUserReducer";
import { Dispatch } from "react";
import API from "../../api";
import toast, { Toaster } from "react-hot-toast";


const setAuthUser = (user_role: any, currentUser: any) => {
  return {
    type: GET_AUTH_USER,
    user_role: user_role,
    currentUser: currentUser,
  };
};

export const getCurrentUser = async () => {
  let res;
  try {
      res = await API.get("/currentuser");
  } catch (err: any) {
      if (err.response.status === 403) {
          window["signout" as keyof Window](PUBLIC_URL);
          // console.log(err.response.data);
      }
      return { error: err?.response?.data?.message || "Something went wrong" };
  }
  if (res.status === 200) {
      localStorage.setItem("email_id", res.data.email_id);
      return res.data;
  } else {
      console.log(res);
      return null;
  }
};

export const getCurrentUserStatus = async () => {
  try {
    const res = await API.get("/currentUserStatus");
    if (res.status === 200) {
      return res.data;
    } else {
      return null;
    }
  } catch (err: any) {
    if (err.response.status === 403) {
      window["signout" as keyof Window](PUBLIC_URL);
      // console.log(err.response.data);
    }
    return { error: err?.response?.data?.message || "Something went wrong" };
  }
};

export const getSIPUsers = async () => {
  const res = await API.get("/supportagents/get");
  if (res.status === 200) {
    return res.data?.filter((x: any) => x.phoneType === "SIP");
  } else return null;
};

export const getUserDetails = async () => {
  const res = await API.get("/userstatus");
  if (res.status === 200) {
    return res.data;
  } else return null;
};


export const checkAuthentication = () => async (dispatch: Dispatch<any>) => {
  try{
    dispatch({ type: SET_AUTH_LOADING, loading: true });
      const currentUser = await getCurrentUser();
      if (!currentUser) return;
      if (currentUser && currentUser["error"]) {
         console.log(currentUser["error"]);
          dispatch({ type: SET_AUTH_LOADING, loading: false });
          return;
      }
      const userRoles = currentUser?.role_details && currentUser?.role_details.role_name;
      dispatch(
          setAuthUser(userRoles, {
              id: currentUser.user_id,
              first_name: currentUser.first_name,
              last_name: currentUser.last_name,
              email: currentUser.email_id,
              phone: currentUser.phone,
              display_name: currentUser.email_id && currentUser.email_id.split("@")[0],
              type: currentUser.type,
          }),
      );
  }catch(e){
    console.log(e);
  }
};
