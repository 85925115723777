import { Dispatch } from "redux";
// import { fetchNoteList } from "../../../views/home/services/homeAction";
import API, { storeCommentFile } from "../../api";
import { NoteStatus } from "../../constants/noteStatus";
import store from "../";
import { CLEAR_NOTE } from "./noteReducer";
import { PUBLIC_URL } from "../../constants/constant";
import toast from "react-hot-toast";
import { changeNoteAssignedTo, updateNoteStatus } from "./note_action";
// import {history} from "react-router-dom"

const storeAttachments = async (objs: any, comment_id: string) => {
  if (!objs.length) return;
  const res = await storeCommentFile(objs, comment_id);
  return res;
};

const updateNoteWithContent = async (values: any, currentUserId = "") => {
  const updateNoteAssignedData: any = {
    ROWID: values.note_id,
    userID: values.user_id,
    status: values.note_type,
  };
  if (currentUserId !== "") updateNoteAssignedData.lastActionID = currentUserId;

  const updateNoteRes: any = await API.post(
    "/notes/update",
    updateNoteAssignedData
  );

  return updateNoteRes;
};

export const createCommentFunc = async (
  { noteId, userId, commentContent, attachments }: any,
  type = "INFO"
) => {
  const createCommentData = {
    content: commentContent,
    noteID: noteId,
    userID: userId,
    type: type,
  };

  const comment: any = await API.post(
    "/notes/createcomment",
    createCommentData
  );
  const commentID = comment.data.id;
  if (attachments && type === "COMMENT") {
    const attachmentRes: any = await storeAttachments(attachments, commentID);
    if (attachmentRes) {
      console.log(attachmentRes);
    }
    return attachmentRes;
  }
  return null;
};

export const sendSms = async (values: any) => {
  const currentuser = store.getState().authUserReducer.currentUser;
  const currentUserName = currentuser ? currentuser.first_name : "";
  console.log("currentUserName", currentUserName);
  const bodyToSms = {
    sms_to: values?.contactNumber,
    sms_body: `${values.content} - ${currentUserName} @Growthzilla`,
    sms_from: values.smsFrom,
    attachments: values.attachments,
  };
  console.log("bodyToSms", bodyToSms);
  try {
    const sendSmsRes: any = await API.post("/sendsms", bodyToSms);
    return sendSmsRes;
  } catch (e: any) {
    toast.error(e);
  }
};

export const sendEmail = async (values: any) => {
  const response = await API.get(`/getlastcomment/${values.noteId}`);
  console.log("response", response.data);
  const bodyToEmail = {
    To: values.emailId,
    Content: values.content,
    Subject: `ticket number : ${values.ticketNumber}`,
    attachments: values.attachments,
    in_reply_to: response?.data?.SupportEmail?.messageId,
    Reference: response?.data?.SupportEmail?.Reference
      ? response?.data?.SupportEmail?.Reference +
        " " +
        response?.data?.SupportEmail?.messageId
      : response?.data?.SupportEmail?.messageId,
  };
  try {
    console.log("bodyToemail", bodyToEmail);
    const sendEmailRes: any = await API.post("/email/sendemail", bodyToEmail);
    return sendEmailRes.data ? true : false;
  } catch {
    return false;
  }
};

export const navigateToLens = () => async (dispatch: Dispatch<any>) => {
  try {
    toast.success("Success: Lens button clicked");
    const token = await API.get("/oauth/accessToken");
    let url = "";
    if (token.data.token_available) {
      const response = await API.get("/oauth/lensLink/");
      url = response.data.url;
    } else {
      // const ngrok = "https://6eb5-2404-7c00-47-28d5-c50b-1e53-16fd-9680.ap.ngrok.io";
      const client_id = await API.get("/oauth/client_id/");
      url = `https://accounts.zoho.com/oauth/v2/auth?response_type=code&client_id=${client_id.data.id}&scope=ZohoLens.sessionapi.CREATE&redirect_uri=${PUBLIC_URL}/server/growthzilla_notes_function/oauth&prompt=consent`;
    }
    window.open(url);
  } catch (e) {
    toast.error("Error: Lens Support Failed");
  }
};

export const storeComment = async (values: any) => {
  const {
    content,
    noteStatus,
    replySMSorEmail,
    attachments,
    noteId,
    contactNumber,
    emailId,
    noteType,
    noteAssigned,
  } = values;
  try {
    const currentUser = store.getState().authUserReducer.currentUser;
    const isSalesUser = store.getState().authUserReducer.isSalesUser;
    const currentUserId = currentUser ? currentUser.id : "";

    // let commentPostResponse;
    let attachmentRes;
    const type = replySMSorEmail
      ? noteType === "EMAIL"
        ? "EMAIL"
        : "SMS"
      : "COMMENT";
    console.log(type);

    if (!noteAssigned) {
      console.log("note assigned to", noteAssigned);
      await changeNoteAssignedTo({
        userUUID: currentUserId,
        noteUUID: noteId,
        to_user: currentUser.display_name,
      });
    }
    if (noteStatus === NoteStatus.UNMANAGED) {
      // console.log("note satus change");
      await updateNoteStatus({
        updatedNoteStatus: NoteStatus.OPENED,
        currentNoteStatus: noteStatus,
        noteId: noteId,
      });
    }

    if ((content || attachments) && !replySMSorEmail) {
      toast.promise(
        createCommentFunc(
          { ...values, userId: currentUserId, commentContent: content },
          type
        ),
        {
          loading: `Adding Comment`,
          success: () => `Comment Added`,
          error: (error) => `Error: ${error}`,
        }
      );
    }
    console.log("replySMSorEmail", replySMSorEmail, noteStatus);
    if (replySMSorEmail) {
      if (noteType === "EMAIL") {
        toast.promise(
          sendEmail({
            ...values,
            attachments: attachmentRes,
          }),
          {
            loading: `Sending Email`,
            success: () => `Email Sent`,
            error: (error) => `Error: ${error}`,
          }
        );
      } else {
        try {
          toast.promise(
            sendSms({
              ...values,
              smsFrom: isSalesUser ? currentUser?.phone : "",
              attachments: attachmentRes,
            }),
            {
              loading: `Sending SMS`,
              success: () => `SMS Sent`,
              error: (error) => `Error: ${error}`,
            }
          );

          if (replySMSorEmail && contactNumber)
            await createCommentFunc(
              {
                ...values,
                userId: currentUserId,
                commentContent: `SMS sent to ${contactNumber} : <br> ${content}`,
              },
              type
            );
        } catch (e) {
          toast.error("Error:SMS sent failed");
          console.log(e);
        }
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const deleteComment =
  (comment: any) => async (dispatch: Dispatch<any>) => {
    try {
      // console.log("comment", comment);
      const res: any = await API.post("/comment/delete", {
        id: comment.id,
        noteID: comment.noteID,
      });
      if (res.data.id) {
        toast.success("Success: Comment Deleted");
      }
    } catch (e) {
      toast.error("Error: Failed to Delete Comment.");
    }
  };
