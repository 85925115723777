/* eslint-disable prefer-const */
import axios from "axios";
import { PUBLIC_URL } from "../constants/constant";

// export const PUBLIC_URL = "http://localhost:3000";
// export const PUBLIC_URL = "https://growthzilla-notes-759987269.development.catalystserverless.com";

export const baseURL = `${PUBLIC_URL}/server/growthzilla_notes_function/`;
const url = new URL(window.location.href);

const checkSessionExpiry = (res: any) => {
  const email = localStorage.getItem("email_id");
  if (!email) return;
  if (
    res?.data?.status === "failure" &&
    res?.data?.data?.error_code === "NO_ACCESS"
  ) {
    window["signout" as keyof Window](PUBLIC_URL);
  }
};

const API = axios.create({
  baseURL: `${PUBLIC_URL}/server/growthzilla_notes_function/`,
  headers: {
    ZCFKEY: url.searchParams.get("api_key") || "",
    ZCFUID: url.searchParams.get("user_id") || "",
  },
});

API.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    checkSessionExpiry(response);
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const { response } = error;
    checkSessionExpiry(response);
    // console.log(error);
    return Promise.reject(error);
  }
);

export default API;

export const getBusinessListAPI = (isSales: any) =>
  API.get(`/listBusiness${isSales ? "?type=PROSPECT" : ""}`);

export const storeCommentFile = async (attachment: any, comment_id: string) => {
  try {
    const data = new FormData();
    for (let i = 0; i < attachment.length; i++) {
      data.append("files", attachment.item(i));
    }
    data.append("commentID", comment_id);

    // const res: any = await uploadApi.post("/", { code: attachment });
    const res: any = await axios.post(
      `${PUBLIC_URL}/server/growthzilla_notes_function/uploadfile`,
      data,
      {}
    );
    return res.data;
  } catch (e) {
    console.log(e);
    return null;
  }
};
