import CallModal from "components/modal/callModal";
import SMSModal from "components/modal/smsModal";
import { IBusinessDetails } from "constants/Interfaces/notesInterface";
import InlineDelete from "components/inlineDelete";
import { Edit, MessageSquare, Phone } from "lucide-react";
import { useState } from "react";
import { useAppDispatch } from "store/index";
import { useNavigate } from "react-router-dom";
import { deleteContact } from "store/business/businessAction";
import { useQueryClient } from "@tanstack/react-query";
import Modal from "components/modal";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { updateContactByNotes } from "store/notes/note_action";

interface IContactCard {
  name: string;
  phone: string;
  business: IBusinessDetails;
  contactID: string;
}

export const ContactCard = ({
  name,
  phone,
  business,
  contactID,
}: IContactCard) => {
  const [makeCall, setMakeCall] = useState<boolean>(false);
  const [contactEditModal, setContactEditModal] = useState<boolean>(false);
  const [sendSMS, setSendSMS] = useState<boolean>(false);
  const query = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      contactUUID: contactID,
      businessUUID: business.id,
      contactName: "",
    },
    onSubmit: async (values) => {
      try {
        if (!values?.contactUUID) {
          toast.error("There is no phone or email to save this contact");
        } else if (!values?.contactName) {
          toast.error("Please enter the valid name for this contact");
        } else {
          toast.promise(
            dispatch(updateContactByNotes(values)),
            {
              loading: "Updating contact...",
              success: () => {
                query.invalidateQueries(["businessBatchNotes"]);
                setContactEditModal(false);
                formik.setFieldValue("contactName", "");
                return "Contact updated!"
              },
              error: (error) => `Error: ${error}`,
            }
          );
        }
      } catch (e: any) {
        toast.error(e);
      }
    },
  });

  const handleConfirmDelete = async () => {
    try{
      await dispatch(deleteContact({id:contactID}, navigate));
    }catch(e){
      console.log(e);
    }finally{
      query.invalidateQueries(["businessBatchNotes"]);
    }
};
  return (
    <>
      <div className="flex flex-col text-white border-b border-[#431f5e] py-2 h-fit">
        <div className="flex justify-between">
          <div className="flex gap-4 items-center">
            <div className="flex flex-col gap-2">
              <button onClick={() => setMakeCall(true)}>
                <Phone
                  size={18}
                  className=" text-white/30 cursor-pointer hover:text-white "
                />
              </button>
              <button onClick={() => setSendSMS(true)}>
                <MessageSquare
                  size={18}
                  className=" text-white/30 cursor-pointer hover:text-white "
                />
              </button>
            </div>
            <div className="flex flex-col ">
              <p className="text-[12px]">{name ?? "UNKNOWN"}</p>
              <p>{phone}</p>
            </div>
          </div>
          <div className="flex flex-col gap-2 items-end">
            <button onClick={()=> setContactEditModal(true)}>
            <Edit
              size={16}
              className="flex cursor-pointer  text-white/40 hover:text-white"
            />
            </button>
           <InlineDelete
              className="text-white/40"
              onAccept={() => handleConfirmDelete()}
            />
            {/* <Trash
              size={18}
              className="group-hover:flex hidden cursor-pointer text-white/30 hover:text-white "
            /> */}
          </div>
        </div>
      </div>
      <CallModal
        isOpen={makeCall}
        onClose={() => setMakeCall(false)}
        callingTo={name}
        contactID={contactID}
        businessName={business?.name}
        businessID={business?.id}
        phoneNumber={phone}
      />
      <SMSModal
        isOpen={sendSMS}
        onClose={() => setSendSMS(false)}
        title={` Send SMS to ${name}`}
        businessID={business?.id}
        businessName={business?.name}
        contact={phone}
      />
      <Modal isOpen={ contactEditModal} onClose={()=>setContactEditModal(false)} title="Change Contact Name" showCloseButton>
      <div className="flex flex-col gap-2 px-6">
            <div className="flex gap-1 flex-col">
              <label className="text-[14px] font-medium" htmlFor="key">
                Contact Name
              </label>
              <input
                className="py-1 px-2 border rounded-sm focus:outline-blue-500/50"
                id="key"
                type="text"
                onChange={(e:any)=>formik.setFieldValue("contactName", e.target.value)}
              />
            </div>
            <button
              onClick={() => formik.handleSubmit()}
              className="bg-primary text-white px-4 py-2 mt-2 rounded-sm"
            >
              Save
            </button>
          </div>

      </Modal>
    </>
  );
};

export default ContactCard;
