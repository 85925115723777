import Modal from "components/modal";
import { Contact, Plus } from "lucide-react";
import { useState } from "react";
import ContactCard from "./components/contactCard";
import { createContactByBusiness } from "store/business/businessAction";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "store/index";
import { IBusinessDetails, IContact } from "constants/Interfaces/notesInterface";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";

export const ContactSection = ({ business }: {business:IBusinessDetails}) => {
    const [isOpenModal, setModelOpen] = useState<boolean>(false);
    const {businessUUID} = useParams();
    const query = useQueryClient();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    console.log(business,businessUUID);
    const {isSalesUser} = useAppSelector((state) => state.authUserReducer);

    const formik = useFormik({
      initialValues: {
          person: "",
          phone: "",
          businessUUID:businessUUID,
          type: isSalesUser ? "PROSPECT" : undefined,
      },
      onSubmit: async (values, { resetForm }) => {
        console.log(values)
        try{
          await dispatch(createContactByBusiness(values, navigate));
        }catch(e){
          toast.error( `Error:${e}`)
        }finally{
          query.invalidateQueries(["businessBatchNotes"]);
        }
          resetForm();
          setModelOpen(false);
      },
  });
    return (
      <div className="flex flex-col gap-2  overflow-y-auto">
        <div className="flex justify-between  text-purple-500">
          <div className="flex items-center gap-2">
            <Contact size={20} />
            <h5 className="">Contacts</h5>
          </div>
          <Plus
            size={18}
            className=" cursor-pointer"
            onClick={() => setModelOpen(true)}
          />
        </div>
        {/* contact list */}
        <div className="flex flex-col gap-1 max-h-[60vh] overflow-y-auto">
          {business?.contacts?.map((contact: IContact, index: number) => {
            return (
              <ContactCard
                key={index}
                contactID={contact?.id || ""}
                business={business}
                name={contact?.name || ""}
                phone={contact?.phone || ""}
              />
            );
          })}
        </div>
        <Modal
          isOpen={isOpenModal}
          showCloseButton
          title="Add Contact"
          onClose={() => setModelOpen(false)}
        >
          <div className="flex flex-col gap-2 px-6">
            <div className="flex gap-1 flex-col">
              <label className="text-[14px] font-medium" htmlFor="key">
                Contact Name
              </label>
              <input
                className="py-1 px-2 border rounded-sm focus:outline-blue-500/50"
                id="key"
                type="text"
                onChange={(e:any)=>formik.setFieldValue("person", e.target.value)}
              />
            </div>
            <div className="flex gap-1 flex-col">
              <label className="text-[14px] font-medium" htmlFor="value">
                Phone Number
              </label>
              <input
                className="py-1 px-2 border rounded-sm focus:outline-blue-500/50"
                id="value"
                type="text"
                onChange={(e:any)=>formik.setFieldValue("phone", e.target.value)}
              />
            </div>
            <button
              onClick={() => formik.handleSubmit()}
              className="bg-primary text-white px-4 py-2 mt-2 rounded-sm"
            >
              Save
            </button>
          </div>
        </Modal>
      </div>
    );
  };

  export default ContactSection
  