import InlineDelete from "components/inlineDelete";
import Modal from "components/modal";
import {
  BusinessMetaType,
  IBusinessDetails,
  IBusinessMeta,
} from "constants/Interfaces/notesInterface";
import { UserCircle, Plus } from "lucide-react";
import { useState } from "react";
import { createBusinessMeta, deleteBusinessMeta } from "store/business/businessAction";
import { useAppDispatch } from "store/index";
import { useQueryClient } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";

export const Accounts = ({
  businessMeta,
}: {
  businessMeta: IBusinessMeta[];
}) => {
  const [isOpenModal, setModelOpen] = useState<boolean>(false);
  const { businessUUID } = useParams();
  const dispatch = useAppDispatch();
  const query = useQueryClient();
  const formik = useFormik({
    initialValues: {
        label: "",
        value: "",
        type: BusinessMetaType.ACCOUNT,
        businessUUID: businessUUID,
    },
    onSubmit: async (value, { resetForm }) => {
      console.log(value);
      try {
        await dispatch(createBusinessMeta(value));
      } catch (e) {
        console.log(e);
      } finally {
        query.invalidateQueries(["businessBatchNotes"]);
        resetForm();
        setModelOpen(false);
      }
    },
});

  const handleConfirmDelete = async (metaId:string) => {
    try{
      await dispatch(deleteBusinessMeta({id:metaId}));
    }catch(e){
      console.log(e);
    }finally{
      query.invalidateQueries(["businessBatchNotes"]);
    }
};
  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between  text-purple-500">
        <div className="flex items-center gap-2">
          <UserCircle size={20} />
          <h5 className=""> Account Info</h5>
        </div>
        <Plus
          className=" cursor-pointer"
          size={18}
          onClick={() => setModelOpen(true)}
        />
      </div>

      {businessMeta?.length > 0 ? (
        businessMeta?.map((meta, index) => {
          return (
            <div key={index} className="flex justify-between text-white text-[12px] border-b border-[#431f5e]">
              <div className="flex flex-col">
              <span>{meta.title}</span>
              <span className="text-[16px]">{meta.content}</span>
              </div>
              <InlineDelete
              className="text-white"
              onAccept={() => handleConfirmDelete(meta?.id)}
            />
            </div>
          );
        })
      ) : (
        <h5 className="text-white text-[14px]"> Not found !</h5>
      )}

      <Modal
        isOpen={isOpenModal}
        showCloseButton
        title="Add Accounts"
        onClose={() => setModelOpen(false)}
      >
        <div className="flex flex-col gap-2 px-6">
          <div className="flex gap-1 flex-col">
            <label className="text-[14px] font-medium" htmlFor="key">
              Key
            </label>
            <input
              className="py-1 px-2 border rounded-sm focus:outline-blue-500/50"
              id="key"
              type="text"
              onChange={(e:any)=>formik.setFieldValue("label", e.target.value)}
            />
          </div>
          <div className="flex gap-1 flex-col">
            <label className="text-[14px] font-medium" htmlFor="value">
              Value
            </label>
            <input
              className="py-1 px-2 border rounded-sm focus:outline-blue-500/50"
              id="value"
              type="text"
              onChange={(e:any)=>formik.setFieldValue("value", e.target.value)}
            />
          </div>
          <button
            onClick={()=>formik.handleSubmit()}
            // onClick={() => console.log("cliked")}
            className="bg-primary text-white px-4 py-2 mt-2 rounded-sm"
          >
            Save
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Accounts;
