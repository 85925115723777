import axios from "axios";

const webhook_url: any = process.env.REACT_APP_SLACK_API_URL;

export interface slack_data_interface {
  text: string;
}

export const slack_notification = async (slack_data: slack_data_interface) => {
  // console.log("slack-data", slack_data);
  return await axios.post(webhook_url, JSON.stringify(slack_data), {
    withCredentials: false,
    transformRequest: [
      (data, headers: any) => {
        delete headers.post["Content-Type"];
        return data;
      },
    ],
  });
};
