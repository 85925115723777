import { Dispatch } from "redux";
import API from "../../api";
// import { API, graphqlOperation } from "aws-amplify";
import store from "../";
import { CLEAR_NOTE } from "./noteReducer";
import toast from "react-hot-toast";

// export const createBusinessByContact = (values: any) => async (dispatch: Dispatch<any>) => {
//     try {
//         console.log(values);
//         const businessQuery = `
//         mutation createBusinessByContact {
//           createBusiness(input: {name: "${values.business_name}", contactID: "${values.contactUUID}"}) {
//             id
//           }
//         }
//         `;
//         const businessRes: any = await API.graphql(graphqlOperation(businessQuery));
//         if (!businessRes.data.createBusiness.id) {
//             dispatch(
//                 setToasterState({
//                     appear: true,
//                     title: "error",
//                     note: "Failed to create business.",
//                     name: "Note",
//                 }),
//             );
//         }
//         console.log(businessRes.data.createBusiness.id);
//         const updateNoteQuery = `
//             mutation MyMutation {
//               updateNote(input: {id: "${values.noteUUID}", businessID: "${businessRes.data.createBusiness.id}", noteBusinessId: "${businessRes.data.createBusiness.id}"}) {
//                 id
//                 status
//                 type
//                 content
//                 createdAt
//                 updatedAt
//                 Business {
//                   id
//                   name
//                   contacts {
//                     items {
//                       name
//                       id
//                       phone
//                     }
//                   }
//                   address
//                 }
//                 Comments {
//                   items {
//                     createdAt
//                     id
//                     type
//                     content
//                     Attachments {
//                       items {
//                         name
//                         path
//                       }
//                     }
//                     User {
//                       first_name
//                       last_name
//                       id
//                     }
//                   }
//                 }
//                 User {
//                   first_name
//                   last_name
//                   id
//                 }
//                 Attachments {
//                   items {
//                     name
//                     path
//                   }
//                 }
//                 Contact {
//                   id
//                   name
//                   phone
//                   email
//                   businessID
//                 }
//               }
//             }
//         `;
//         const noteBusiness: any = await API.graphql(graphqlOperation(updateNoteQuery));
//         if (!noteBusiness.data.updateNote.id) {
//             dispatch(
//                 setToasterState({
//                     appear: true,
//                     title: "error",
//                     note: "Business created but cannot liked with note.",
//                     name: "Note",
//                 }),
//             );
//         }
//         dispatch(fetchNoteList(searchFilter));
//         dispatch(
//             setToasterState({
//                 appear: true,
//                 title: "success",
//                 note: "Business Created and liked with note.",
//                 name: "Note Business",
//             }),
//         );
//     } catch (e) {
//         console.log("error");
//         dispatch(
//             setToasterState({
//                 appear: true,
//                 title: "error",
//                 note: "Failed to create comment.",
//                 name: "Note",
//             }),
//         );
//     }
// };

export const updateBusinessByNoteId =
  async (value: any) =>  {
    console.log(value);
    const updateBusinessData = {
      ROWID: value.noteId,
      businessID: value.businessId,
    };
    const updateContactData = {
      ROWID: value.contactId,
      businessID: value.businessId,
    };
    try {
      const updateBusinessRes: any = await API.post(
        "/notes/update",
        updateBusinessData
      );
      if (value.contactId) await API.post("/contact/update", updateContactData);
      if (updateBusinessRes.data.id) {
        // dispatch({ type: CLEAR_NOTE, noteID: value.noteUUID });
        // dispatch(fetchNoteList(store.getState().homeReducer.searchFilter));
        toast.success("Success: Updated business from note. ");
      } else {
        toast.error("Error: Failed to update business note.");
      }
    } catch (e) {
      console.log(e);
      toast.error("Error: Failed to update business note.");
    }
  };
