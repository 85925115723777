export const GET_ALL_NOTES = "GET_ALL_NOTES";
export const UPDATE_NOTES = "UPDATE_NOTES";
export const APPEND_NOTES = "APPEND_NOTES";
export const UPDATE_SEARCH_FILTER = "UPDATE_SEARCH_FILTER";
export const INCREASE_PAGE = "INCREASE_PAGE";
export const SET_PAGE = "SET_PAGE";
export const RESET_PAGE = "RESET_PAGE";
export const NOTE_ADDED = "NOTE_ADDED";
export const BUSINESS_UPDATED = "BUSINESS_UPDATED";
export const BUSINESS_ADDED = "BUSINESS_ADDED";
export const RESET_NEW_SEARCH = "RESET_NEW_SEARCH";
export const SEARCH_TEXT = "SEARCH_TEXT";
export const SET_LOADING = "SET_LOADING";
export const SET_USER_LIST = "SET_USER_LIST";

export const POPULATE_NOTES = "POPULATE_NOTES";

interface HomeReducerInterface {
  notes: any;
  noteAdded: boolean;
  businessUpdated: boolean;
  searchFilter: any;
  searchFilterUpdate: boolean;
  searchText: boolean;
  loading: boolean;
  userList: any;
}

const initialState: HomeReducerInterface = {
  notes: [],
  noteAdded: false,
  businessUpdated: false,
  searchFilterUpdate: false,
  searchFilter: { page: 1 },
  searchText: false,
  loading: false,
  userList: [],
};

const homeReducer = (state = initialState, action: any) => {
  const { type, notes, searchFilter, createdAt, loading, userList } = action;
  switch (type) {
    case GET_ALL_NOTES:
      return {
        ...state,
        notes: notes,
      };
    case UPDATE_NOTES:
      return {
        ...state,
        notes: notes,
      };
    case APPEND_NOTES:
      return {
        ...state,
        notes: [...state.notes, ...notes],
      };
    case UPDATE_SEARCH_FILTER:
      return {
        ...state,
        searchFilter: { ...searchFilter, page: state.searchFilter.page },
        searchFilterUpdate: !state.searchFilterUpdate,
      };
    case INCREASE_PAGE:
      return {
        ...state,
        searchFilter: {
          ...state["searchFilter"],
          page: state.searchFilter.page + 1,
          createdAt: createdAt,
        },
      };
    case RESET_NEW_SEARCH:
      return {
        ...state,
        searchFilter: {
          ...state["searchFilter"],
          newSearch: false,
        },
      };
    case SET_PAGE:
      return {
        ...state,
        searchFilter: { ...state["searchFilter"], page: searchFilter.page },
      };
    case RESET_PAGE:
      return {
        ...state,
        searchFilter: {
          page: 1,
        },
        loading: false,
      };
    case NOTE_ADDED:
      return {
        ...state,
        noteAdded: !state.noteAdded,
      };
    case BUSINESS_UPDATED:
      return {
        ...state,
        businessUpdated: !state.businessUpdated,
      };
    case SEARCH_TEXT:
      return {
        ...state,
        searchText: !state.searchText,
      };
    case SET_USER_LIST:
      return {
        ...state,
        userList,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: loading,
      };
    default:
      return state;
  }
};

export default homeReducer;
