import { Users } from "lucide-react";
import { extractUsername } from "../../utils/extractUsername";
import StatusCard from "./components/statusCard";
import { useQuery } from "@tanstack/react-query";
import {
  getSIPUsers,
  getUserDetails,
} from "../../store/auth_user/authUserAction";
import cn from "classnames";

const SIPUsers = ({ className }: { className?: string }) => {
  const { data: sipUsers, isLoading: isSipUsersLoading } = useQuery(
    ["SIPUsers"],
    getSIPUsers
  );
  const { data: userDetails, isLoading: isUsersDetailsLoading } = useQuery(
    ["userDetails"],
    getUserDetails
  );

  const SIPUserDetails = sipUsers?.map((item1: any) => {
    const match = userDetails?.find(
      (item2: any) => extractUsername(item2.email) === item1.userId
    );
    if (match) {
      return {
        ...item1,
        id: match.id,
        userID: match.ROWID,
        MODIFIEDTIME: match.MODIFIEDTIME,
        status: match.status,
        userStatusModified: match.userStatusModified,
        phoneStatusModified: match.phoneStatusModified,
        supportStatus: match.supportstatus,
      };
    }
    return item1;
  });

  return (
    <aside
      className={cn(
        className,
        "sticky top-[72px] max-h-[calc(100vh-72px)] xl:w-[25vw] lg:w-[45vw]  bg-white"
      )}
    >
      <div className="flex flex-col ">
        {/* support Team */}
        <div className="flex gap-2 px-[16px] py-[20px] items-center border-b border-[#E6EBEF] text-[#2a2a2a] w-full">
          <Users size={20} />
          <h2 className="font-medium ">Support Team</h2>
        </div>
        {/* peoples */}
        {SIPUserDetails?.map((user: any) => {
          return <StatusCard key={user.userId} user={user} />;
        })}
      </div>
    </aside>
  );
};

export default SIPUsers;
