import { Dispatch } from "react";

import API from "../../api/";
import store from "../../store";
import {
  SET_LOADING,
  INCREASE_PAGE,
  SET_PAGE,
  UPDATE_SEARCH_FILTER,
  APPEND_NOTES,
  SET_USER_LIST,
} from "./homeReducer";
import toast from "react-hot-toast";

const setNoteList = (notes: any) => {
  return {
    type: APPEND_NOTES,
    notes: notes,
  };
};

export const fetchNoteList =
  (searchFilter: any) => async (dispatch: Dispatch<any>) => {
    dispatch({ type: SET_LOADING, loading: true });
    try {
      const isSalesUser = store.getState().authUserReducer.isSalesUser;
      const currentUser = store.getState().authUserReducer.currentUser;
      const userID = isSalesUser
        ? currentUser?.type !== "ADMIN"
          ? currentUser?.id
          : undefined
        : undefined;
      // console.log(searchFilter);
      const searchFilterData = {
        ...searchFilter,
        channel: isSalesUser ? "SALES" : undefined,
        userID,
      };
      const res: any = await API.post("/notes/query", searchFilterData);
      // console.log("Res ", res.data);
      // if (searchFilter.page === 1 && (!res.data || res.data.length <= 3)) {
      //   dispatch({ type: INCREASE_PAGE });
      //   // console.log(res.data);
      // } else if (searchFilter.page === 1) {
      //   dispatch({
      //     type: SET_PAGE,
      //     searchFilter: { page: Math.ceil(res.data.length / 5 + 1), userID },
      //   });
      //   console.log(searchFilter);
      // }
      // let notes = store.getState().homeReducer.notes.concat(res.data);
      // notes = notes.filter(unique);
      if (res.data) {
        // const ids = res.data.map((note: any) => note.id);
        // const batch: any = await API.post("/notes/batch", { ids });
        // console.log(batch.data);
        dispatch(setNoteList(res.data));
      } else {
        dispatch(setNoteList([]));
      }
    } catch (err: any) {
      toast.error(`Error: ${err}`);
      //   dispatch(
      //     setToasterState({
      //       appear: true,
      //       title: "error",
      //       note: "Failed to fetch notes.",
      //       name: "Notes",
      //     })
      //   );
    }
    dispatch({ type: SET_LOADING, loading: false });
  };

export const fetchNoteListPopulated =
  (searchFilter: any) => async (dispatch: Dispatch<any>) => {
    dispatch({ type: SET_LOADING, loading: true });
    try {
      const isSalesUser = store.getState().authUserReducer.isSalesUser;
      const currentUser = store.getState().authUserReducer.currentUser;
      const userID = isSalesUser
        ? currentUser?.type !== "ADMIN"
          ? currentUser?.id
          : undefined
        : undefined;
      const searchFilterData = {
        ...searchFilter,
        channel: isSalesUser ? "SALES" : undefined,
        userID,
      };
      console.log("----------------------------", { searchFilterData });
      const res: any = await API.post("/notes/batch", searchFilterData);

      if (res.data) {
        dispatch(setNoteList(res.data));
      } else {
        dispatch(setNoteList([]));
      }
    } catch (err) {
      toast.error(`Error: ${err}`);
      //   dispatch(
      //     setToasterState({
      //       appear: true,
      //       title: "error",
      //       note: "Failed to fetch notes.",
      //       name: "Notes",
      //     })
      //   );
    }
    dispatch({ type: SET_LOADING, loading: false });
  };

export const setSearchFilter =
  (searchFilter: any) => async (dispatch: Dispatch<any>) => {
    dispatch({ searchFilter: searchFilter, type: UPDATE_SEARCH_FILTER });
  };

export const incrementPage = () => (dispatch: Dispatch<any>) => {
  dispatch({ type: INCREASE_PAGE });
};

export const setLoading = (value: boolean) => (dispatch: Dispatch<any>) => {
  dispatch({ type: SET_LOADING, loading: value });
};

export const fetchUserList = () => async (dispatch: Dispatch<any>) => {
  try {
    const userRes = await API.get("/listUser");
    const userData = await userRes.data || [];
    if (userData) {
      dispatch({ type: SET_USER_LIST, userList: userData });
    }
  } catch (e) {
    toast.error(`Error: Failed to fetch user list`);
  }
};
