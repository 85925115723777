import { Clock, Mail, Merge, MessageSquare, Play, Reply } from "lucide-react";
import moment from "moment";
import React, { useState } from "react";
import {
  IAttachments,
  IComment,
  ITicket,
} from "../../../constants/Interfaces/notesInterface";
import { PSTtoLocal } from "../../../utils/dateConverter";
import TooltipComponent from "components/tooltip";
import cn from "classnames";
import { sortByDateReverse } from "utils/sort_data";
import API from "api/index";
import VideoPlayerModal from "components/modal/videoPlayerModal";
import Modal from "components/modal";
import InlineDelete from "components/inlineDelete";
import { deleteComment } from "store/notes/comment_action";
import { useAppDispatch, useAppSelector } from "store/index";
import { extractUsername } from "utils/extractUsername";
import { useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

const parseJSON = (x: any) => {
  if (!x) return null;
  let val;
  try {
    val = JSON.parse(x);
  } catch {
    return null;
  }
  return val;
};

const isURL = (x: string) => {
  const urlPattern =
    /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(:\d{1,5})?([/?#]\S*)?$/i;
  return urlPattern.test(x);
};

const NoteCommentField = ({
  notes,
  className,
}: {
  notes: ITicket;
  className?: string;
}) => {
  const [videoLink, setVideoLink] = useState<string>("");
  const [commentType, setCommentType] = useState<string>("");
  const [videoId, setVideoId] = useState<string>("");
  const [isVideoPlayerModalOpen, setIsVideoPlayerModal] =
    useState<boolean>(false);
  const [imageLink, setImageLink] = useState<string>("");
  const [fullImageView, setFullImageView] = useState<boolean>(false);
  const { user_role, currentUser } = useAppSelector(
    (state) => state.authUserReducer
  );
  const images = notes?.Attachments?.filter((media: IAttachments) => {
    return media.name?.toLocaleLowerCase() !== "call";
  });

  const query = useQueryClient();

  const handleCommentDelete = async (id: string, noteID: string) => {
    toast.promise(
      API.post("/comment/delete", {
        id,
        noteID,
      }),
      {
        loading: "Deleting Comment...",
        success: "Comment Deleted",
        error: "Failed to Delete Comment",
      }
    );
  };
  const handleVideoPlay = async (
    comment_content: React.SetStateAction<string>,
    comment_type: React.SetStateAction<string>
  ) => {
    setVideoId(comment_content);
    setCommentType(comment_type);
    console.log("Video clicked!");
    try {
      const response = await API.get(
        "/oauth/getRecordingLink/" + comment_content
      );
      console.log(response.data.uri);
      console.log(comment_content);
      setVideoLink(response.data.uri);
      setIsVideoPlayerModal(true);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className={cn("w-full flex flex-col", className)}>
        {/* main message */}
        <div className="w-full flex flex-col gap-1 border-b border-[#f2f4f7]  px-8 py-4">
          <div className=" flex gap-4 flex-wrap">
            {images?.map((image: IAttachments, index: number) => {
              const imageUrl =
                process.env.NODE_ENV === "development"
                  ? image?.path?.[0] === "/"
                    ? `/server/growthzilla_notes_function${image.path}`
                    : image.path
                  : image.path;
              return (
                <img
                  key={image?.id}
                  src={imageUrl || ""}
                  onClick={() => {
                    setFullImageView(true);
                    setImageLink(imageUrl || "");
                  }}
                  alt={
                    image?.name
                      ?.split("/")
                      .pop()
                      ?.replace(
                        /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}-/g,
                        ""
                      ) || "note_images"
                  }
                  className="aspect-auto max-w-[180px] cursor-pointer hover:border"
                />
              );
            })}
          </div>
          <p
            className="break-all"
            dangerouslySetInnerHTML={{
              __html: `${notes?.content?.replace(/\n/g, "<br />")}`,
            }}
          />
          <div className="flex gap-4">
            <p className="text-[#9F9F9F]  text-[13px]">
              {notes?.User
                ? `${notes?.User?.first_name} ${notes?.User?.last_name}`
                : notes?.SupportEmail?.sender_email_address}
            </p>{" "}
            <TooltipComponent
              content={
                <div className="text-[#9F9F9F] text-[13px]">
                  {`${moment(notes?.createdAt, "YYYY-MM-DD hh-mm-ss").format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )} PST`}
                </div>
              }
            >
              <div className="flex gap-2 items-center text-[#9F9F9F]">
                <Clock size={14} />
                <p className=" text-[13px] ">
                  {moment(PSTtoLocal(notes?.createdAt)).fromNow()}
                </p>
              </div>
            </TooltipComponent>
          </div>
        </div>
        {/* reply area */}
        <div className="flex flex-col px-8 py-4 gap-4">
          {/* chat reply card */}
          {sortByDateReverse(notes?.Comments)?.map((comment: IComment) => {
            return (
              <div key={comment.id} className="flex flex-col items-end ">
                {(comment.type === "INFO" || comment.type === "MERGE_INFO") && (
                  <div className="w-full flex gap-2   lg:items-center pl-4 ">
                    {/* <InfoIcon className="text-[#cccccc]" size={20} /> */}
                    <div className="flex flex-col lg:flex-row lg:gap-6  w-full">
                      {/* <div className="flex border border-[#f2f4f7] rounded-lg px-4 gap-6 py-1 w-full"> */}
                      <p className="text-[#9F9F9F]  text-[14px]">
                        {`${comment.content}`}
                      </p>
                      <p className="text-[#E6EBEF] hidden lg:inline-block">|</p>
                      <div className="flex gap-4">
                        <p className="text-[#c2c2c2] text-[14px]">
                          {` ${comment.User?.first_name} ${comment.User?.last_name} `}
                        </p>

                        <TooltipComponent
                          content={
                            <div className="text-[#c2c2c2] text-[13px]">
                              {`${moment(
                                comment.createdAt,
                                "YYYY-MM-DD hh-mm-ss"
                              ).format("MMMM Do YYYY, h:mm:ss a")} PST`}
                            </div>
                          }
                        >
                          <div className="flex gap-2 items-center text-[#c2c2c2]">
                            <Clock size={14} />
                            <p className=" text-[13px] ">
                              {moment(PSTtoLocal(comment.createdAt)).fromNow()}
                            </p>
                          </div>
                        </TooltipComponent>
                      </div>
                    </div>
                  </div>
                )}

                {(comment.type === "COMMENT" ||
                  comment.type === "EMAIL" ||
                  comment.type === "SMS") && (
                  <div className="flex w-full items-center gap-4 pl-4 ">
                    {comment.type === "COMMENT" && (
                      <Reply className="text-[#cccccc]" size={20} />
                    )}
                    {comment.type === "EMAIL" && (
                      <Mail className="text-[#cccccc]" size={20} />
                    )}
                    {comment.type === "SMS" && (
                      <MessageSquare className="text-[#cccccc]" size={20} />
                    )}

                    <div className=" w-full flex justify-between gap-2 border border-[#f2f4f7] rounded-lg px-4 py-2">
                      <div className="flex gap-2 flex-col">
                        <div className=" flex gap-4 flex-wrap">
                          {!!(comment.Attachments?.length > 0) &&
                            comment.Attachments?.map(
                              (image: IAttachments, index: number) => {
                                const imageUrl =
                                  process.env.NODE_ENV === "development"
                                    ? image?.path?.[0] === "/"
                                      ? `/server/growthzilla_notes_function${image.path}`
                                      : image.path
                                    : image.path;
                                return (
                                  <img
                                    key={image?.id}
                                    src={imageUrl || ""}
                                    onClick={() => {
                                      setFullImageView(true);
                                      setImageLink(imageUrl || "");
                                    }}
                                    alt={
                                      image?.name
                                        ?.split("/")
                                        .pop()
                                        ?.replace(
                                          /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}-/g,
                                          ""
                                        ) || "note_images"
                                    }
                                    className="aspect-auto max-w-[180px] cursor-pointer hover:border"
                                  />
                                );
                              }
                            )}
                        </div>
                        <div className=" w-full flex flex-col gap-2 items-start">
                          <p
                            className="break-all"
                            dangerouslySetInnerHTML={{
                              __html: `${comment.content?.replace(
                                /\n/g,
                                "<br />"
                              )}`,
                            }}
                          />

                          <div className="flex w-full gap-6 ">
                            <p className="text-[#c2c2c2]  text-[13px]">
                              {comment.User
                                ? `${comment.User?.first_name} ${comment.User?.last_name}`
                                : comment.supportEmail?.sender_email_address}
                            </p>
                            <div className="flex gap-2 items-center text-[#c2c2c2] text-[13px]">
                              {/* <p>7 hours ago</p> */}
                              <TooltipComponent
                                content={
                                  <div className="full-time-view">
                                    {`${moment(
                                      comment.createdAt,
                                      "YYYY-MM-DD hh-mm-ss"
                                    ).format("MMMM Do YYYY, h:mm:ss a")} PST`}
                                  </div>
                                }
                              >
                                <div className="flex gap-2 items-center">
                                  <Clock size={13} />
                                  <p className="ml-xs mb-0">
                                    {moment(
                                      PSTtoLocal(comment.createdAt)
                                    ).fromNow()}
                                  </p>
                                </div>
                              </TooltipComponent>
                            </div>
                          </div>
                        </div>
                      </div>

                      {(user_role.includes("Admin") ||
                        comment.userID === currentUser.id) && (
                        <InlineDelete
                          className="text-black/30"
                          onAccept={() =>
                            handleCommentDelete(comment.id, comment.noteID)
                          }
                        />
                      )}
                    </div>
                  </div>
                )}
                {comment.type === "MERGE_COMMENT" && (
                  <div className="flex w-full items-center gap-4 pl-4 ">
                    <Merge className="text-[#cccccc] rotate-180" size={20} />

                    <div className=" w-full flex justify-between gap-2 border border-[#f2f4f7] rounded-lg px-4 py-2">
                      <div className="flex flex-col gap-2">
                        <div className=" w-full flex flex-col gap-2 items-start">
                          {
                            <p
                              dangerouslySetInnerHTML={{
                                __html: `${comment.content
                                  .split?.(":#metadata:")[0]
                                  ?.replace(/\n/g, "<br />")}`,
                              }}
                              className="break-all"
                            ></p>
                          }
                          <div className="flex w-full gap-6 ">
                            <p className="text-[#c2c2c2]  text-[13px]">
                              {parseJSON(
                                comment.content?.split?.(":#metadata:")[1]
                              )?.contact?.name ?? "Unknown"}
                            </p>
                            <div className="flex gap-2 items-center text-[#c2c2c2] text-[13px]">
                              {/* <p>7 hours ago</p> */}
                              <TooltipComponent
                                content={
                                  <div className="full-time-view">
                                    {`${moment(
                                      comment.createdAt,
                                      "YYYY-MM-DD hh-mm-ss"
                                    ).format("MMMM Do YYYY, h:mm:ss a")} PST`}
                                  </div>
                                }
                              >
                                <div className="flex gap-2 items-center">
                                  <Clock size={13} />
                                  <p className="ml-xs mb-0">
                                    {moment(
                                      PSTtoLocal(comment.createdAt)
                                    ).fromNow()}
                                  </p>
                                </div>
                              </TooltipComponent>
                            </div>
                          </div>
                        </div>
                        <div className=" flex gap-4 flex-wrap">
                          {!!(comment.Attachments?.length > 0) &&
                            comment.Attachments?.map(
                              (image: IAttachments, index: number) => {
                                const imageUrl =
                                  process.env.NODE_ENV === "development"
                                    ? image?.path?.[0] === "/"
                                      ? `/server/growthzilla_notes_function${image.path}`
                                      : image.path
                                    : image.path;
                                if (image.name !== "call") {
                                  return (
                                    <img
                                      key={image?.id}
                                      src={imageUrl || ""}
                                      onClick={() => {
                                        setFullImageView(true);
                                        setImageLink(imageUrl || "");
                                      }}
                                      alt={
                                        image?.name
                                          ?.split("/")
                                          .pop()
                                          ?.replace(
                                            /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}-/g,
                                            ""
                                          ) || "note_images"
                                      }
                                      className="aspect-auto max-w-[180px] cursor-pointer hover:border"
                                    />
                                  );
                                } else return null;
                              }
                            )}
                        </div>
                      </div>
                      {(user_role.includes("Admin") ||
                        comment.userID === currentUser.id) && (
                        <InlineDelete
                          className="text-black/30"
                          onAccept={() =>
                            handleCommentDelete(comment.id, comment.noteID)
                          }
                        />
                      )}
                    </div>
                  </div>
                )}

                {comment.type === "VIDEO" && (
                  <div className="w-full flex gap-2 lg:items-center pl-4 ">
                    {/* <InfoIcon className="text-[#cccccc]" size={20} /> */}
                    <div className="flex flex-col lg:flex-row lg:gap-6  w-full">
                      {/* <div className="flex border border-[#f2f4f7] rounded-lg px-4 gap-6 py-1 w-full"> */}
                      <div className="flex gap-2">
                        <p className="text-[#9F9F9F]  text-[14px]  text-center">
                          Video Call Ended. You can play recording here
                        </p>
                        <button
                          className="text-black/30 "
                          onClick={() => {
                            handleVideoPlay(comment.content, comment.type);
                          }}
                        >
                          <Play className="w-4 h-4 hover:fill-primary" />
                        </button>
                      </div>
                      <TooltipComponent
                        content={
                          <div className="text-[#c2c2c2] text-[13px]">
                            {`${moment(
                              comment.createdAt,
                              "YYYY-MM-DD hh-mm-ss"
                            ).format("MMMM Do YYYY, h:mm:ss a")} PST`}
                          </div>
                        }
                      >
                        <div className="flex gap-2 items-center text-[#c2c2c2]">
                          <Clock size={14} />
                          <p className=" text-[13px] ">
                            {moment(PSTtoLocal(comment.createdAt)).fromNow()}
                          </p>
                        </div>
                      </TooltipComponent>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <VideoPlayerModal
        videoLink={videoLink}
        // videoID={comment?.content}
        // commentType={comment?.type}
        commentType={commentType}
        videoID={videoId}
        isOpen={isVideoPlayerModalOpen}
        onClose={() => setIsVideoPlayerModal(false)}
      />
      <Modal isOpen={fullImageView} onClose={() => setFullImageView(false)}>
        <img
          className="max-w-[80vw] max-h-[80vh] object-contain"
          src={imageLink}
          alt="full_view"
        />
      </Modal>
    </>
  );
};

export default NoteCommentField;
