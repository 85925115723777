import LayoutWrapper from "components/layout";
import Main from "components/main";
import SIPUsers from "features/SIPUsers";
import RecentNotes from "features/recentNotes";
// import { useLocation } from 'react-router-dom';
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import API from "api/index";
import { useAppSelector } from "store/index";
import { TabStatus } from "store/tab/tabSlice";
import NoteAddComment from "features/noteContainer/NoteCard/NoteAddComment";
import NoteCardHeader from "features/noteContainer/NoteCard/NoteCardHeader";
import NoteCardInformation, {
  NoteCardActions,
} from "features/noteContainer/NoteCard/NoteCardInformation";
import NoteCommentField from "features/noteContainer/NoteCard/NoteCommentField";

const SwitchTabContent = [
  {
    value: TabStatus.TIMELINE,
    name: "All",
  },
  {
    value: TabStatus.OPEN,
    name: "Open Tickets",
  },
  {
    value: TabStatus.UNMANAGED,
    name: "Unmanaged Tickets ",
  },
  {
    value: TabStatus.CLOSED,
    name: "Closed Tickets",
  },
];

const NoteDetails = () => {
  // const [data, setData] = useState<any>([]);
  const params = useParams();

  const { isSalesUser } = useAppSelector((state) => state.authUserReducer);

  console.log(params.noteUUID);

  const {
    data: notes,
    isLoading,
    isFetched,
  } = useQuery({
    queryKey: ["noteDetails", params.noteUUID],
    queryFn: async () => {
      try {
        const res = await API.get(`/notes/${params.noteUUID}`, {
          params: {
            channel: isSalesUser ? "SALES" : undefined,
          },
        });
        console.log(res?.data);
        return res?.data;
      } catch (e) {
        console.log(e);
      }
    },
  });

  return (
    <LayoutWrapper>
      <RecentNotes className="hidden lg:inline-block" />
      <Main>
        {isLoading && (
          <div className=" h-[20vh] bg-transparent  flex items-center justify-center">
            <div className="loader"></div>
          </div>
        )}
        {isFetched && (
          <>
            <div className="sticky top-0 py-4 px-4">
              <div className="flex flex-col rounded-md bg-white">
                <NoteCardHeader notes={notes} />
                {/* card body */}
                <div className="flex flex-col">
                  {/* left side */}
                  <NoteCardInformation
                    className="bg-[#FBFCFE] flex justify-between "
                    notes={notes}
                  />
                  {/* right side */}
                  <div className="flex justify-between flex-col w-full">
                    {/* chat arera*/}
                    <NoteCommentField notes={notes} />
                  </div>
                </div>
                <NoteAddComment notes={notes} />
                <div className="flex md:hidden">
                  <NoteCardActions notes={notes} />
                </div>
              </div>
            </div>
          </>
        )}

        {/* Card header */}
      </Main>
      <SIPUsers className="hidden lg:inline-block" />
    </LayoutWrapper>
  );
};

export default NoteDetails;
