import { X } from "lucide-react";
import cn from "classnames";

interface ILeftDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
  type: "left" | "right";
  closeButtonPosition?: "left" | "right";
}

export const Drawer = ({
  isOpen,
  type,
  onClose,
  children,
  className,
  closeButtonPosition = "left",
}: ILeftDrawerProps) => {
  return (
    <>
      <div
        style={{
          transition: "ease-in-out",
          transitionDuration: "0.3s",
          boxShadow: " 0px 4px 20px 0px rgba(52, 60, 70, 0.20)",
        }}
        className={cn(
          className,
          ` ${isOpen ? "fixed opacity-1 max-w-[320px] w-full" : "hidden opacity-0"}  ${
            type==='right' ? "right-0" : "left-0"
          } h-full top-0 z-50 `
        )}
      >
        <div className={` ${closeButtonPosition ==="left"? "":"justify-end"} w-full flex mt-4 px-4`}>
          <button onClick={onClose} className={`  p-1 rounded-md`}>
            <X />
          </button>
        </div>
        {children}
      </div>
    </>
  );
};
export default Drawer;
