import { ITicket } from "../../../constants/Interfaces/notesInterface";
import NoteCardHeader from "./NoteCardHeader";
import NoteCardInformation, { NoteCardActions } from "./NoteCardInformation";
import NoteCommentField from "./NoteCommentField";
import NoteAddComment from "./NoteAddComment";
import cn from "classnames";
import { forwardRef } from "react";

interface cardProps {
  notes: ITicket;
  className?: string;
  itemRefs: { current: (HTMLDivElement | null)[] };
}

const NoteCard = forwardRef(
  ({ notes, className, itemRefs }: cardProps, ref) => {
    return (
      <div
        key={notes?.id}
        className={cn(
          className,
          "w-full flex flex-col bg-white rounded-t-md justify-center scroll-m-20"
        )}
        ref={(el) =>
          itemRefs ? (itemRefs.current[parseInt(notes?.id)] = el) : null
        }
      >
        <NoteCardHeader notes={notes} />
        {/* card body */}
        <div className="flex flex-col">
          {/* left side */}
          <NoteCardInformation
            className="bg-[#FBFCFE] flex justify-between gap-4 items-start"
            notes={notes}
          />
          {/* right side */}
          <div className="flex justify-between flex-col w-full">
            {/* chat area*/}
            <NoteCommentField notes={notes} />
          </div>
        </div>
        <NoteAddComment notes={notes} />
        <div className="flex md:hidden">
          <NoteCardActions notes={notes} />
        </div>
      </div>
    );
  }
);

export default NoteCard;
