import LayoutWrapper from "components/layout";
import Main from "components/main";
import SIPUsers from "features/SIPUsers";
import NoteContainer from "features/noteContainer";
import { Suspense, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import API from "api/index";
import { useAppSelector } from "store/index";
import { useQueryParams } from "hooks/useQueryParams";
import { TabStatus } from "store/tab/tabSlice";
import { ArrowLeft } from "lucide-react";
import SwitchTabs from "features/noteContainer/components/switchtabs";
import { ITicket } from "constants/Interfaces/notesInterface";
import RecentNotes from "features/recentNotes";

export enum myTicketsTabStatus {
  TIMELINE = "TIMELINE",
  OPEN = "OPEN",
  CLOSED = "CLOSED",
}

export interface IMyTicketsTabStatus {
  tab: myTicketsTabStatus;
}

const SwitchTabContent = [
  {
    value: myTicketsTabStatus.TIMELINE,
    name: "All",
  },
  {
    value: myTicketsTabStatus.OPEN,
    name: "Open Tickets",
  },
  {
    value: myTicketsTabStatus.CLOSED,
    name: "Closed Tickets",
  },
];

const MyTickets = () => {
  //   const { businessUUID } = useParams();
  const [items, setItems] = useState({
    [myTicketsTabStatus.TIMELINE]: [],
    [myTicketsTabStatus.OPEN]: [],
    [myTicketsTabStatus.CLOSED]: [],
  });

  const { getQueryParam, setQueryParam } =
    useQueryParams<IMyTicketsTabStatus>();
  // const [data, setData] = useState<ITicket[]>([]);
  const [pages, setPages] = useState<number>(1);
  const [hasMore, setHasMore] = useState(true);
  // const params = useParams();

  const { currentUser } = useAppSelector((state) => state.authUserReducer);
  console.log({ items });

  let tabStatus = getQueryParam("tab");
  useEffect(() => {
    setHasMore(true);
  }, [tabStatus]);

  const { data: noteAssignedData } = useQuery({
    queryKey: ["myNotes", pages, tabStatus],
    queryFn: async () => {
      try {
        const res = await API.get(`notes/user/${currentUser.id}`, {
          params: {
            page: pages,
            status: tabStatus === "TIMELINE" ? undefined : tabStatus,
          },
        });
        if (!res?.data?.length) {
          setHasMore(false);
          return [];
        } else {
          const newNotes: ITicket[] = res?.data?.filter((newNote: ITicket) => {
            // Check if the new note has a different ID or content
            return !items[tabStatus || myTicketsTabStatus.TIMELINE].some(
              (existingNote: ITicket) => existingNote.id === newNote.id
            );
          });
          setItems({
            ...items,
            [tabStatus || TabStatus.TIMELINE]: [
              ...items[tabStatus || myTicketsTabStatus.TIMELINE],
              ...newNotes,
            ],
          });
          // setItems(prevData => ({
          //   ...prevData,
          //   [tabStatus || TabStatus.TIMELINE]: [...prevData[tabStatus || TabStatus.TIMELINE],...newNotes],
          // }));
          // setData([...data, ...newNotes]);
          return [
            ...items[tabStatus || myTicketsTabStatus.TIMELINE],
            ...newNotes,
          ];
        }
      } catch (e) {
        setHasMore(false);
      }
    },
  });

  const handleNext = () => {
    setPages((prevPage) => prevPage + 1);
    console.log(pages);
  };

  return (
    <LayoutWrapper>
      <RecentNotes className="hidden lg:inline-block" />
      <Main>
        <div className="z-20 sticky top-0 flex flex-col md:flex-row md:gap-8 bg-white md:items-center justify-between pt-2 px-4">
          <div className="flex gap-4 items-center">
            <button onClick={() => window.history.back()}>
              <ArrowLeft size={24} />
            </button>
            <div className="flex items-center gap-2 text-primary text-[18px] md:text-[24px] font-bold">
              <h5>My Tickets</h5>
            </div>
          </div>
          <Suspense fallback={<></>}>
            <SwitchTabs
              value={tabStatus || TabStatus.TIMELINE}
              tablist={SwitchTabContent}
              onChange={(e: any) => {
                setPages(1);
                setQueryParam("tab", e);
              }}
            />
          </Suspense>
        </div>
        <NoteContainer
          hasMore={hasMore}
          handleNext={handleNext}
          noteList={items[tabStatus || myTicketsTabStatus.TIMELINE]}
          noDataFound={!items[tabStatus || myTicketsTabStatus.TIMELINE]?.length}
        />
      </Main>
      <SIPUsers className="hidden lg:inline-block" />
    </LayoutWrapper>
  );
};

export default MyTickets;
