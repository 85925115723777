import React, { useEffect, useMemo, useRef, useState } from "react";
import LayoutWrapper from "../components/layout";
import Sidebar from "components/sidebar";
import RecentNotes from "features/recentNotes";
import Main from "components/main";
import NoteContainer from "features/noteContainer";
import SIPUsers from "features/SIPUsers";
import { useQuery } from "@tanstack/react-query";
import API from "api/index";
import store, { useAppDispatch, useAppSelector } from "store/index";
import ContainerHeader from "features/noteContainer/containerHeader/ContainerHeader";
import { useQueryParams } from "hooks/useQueryParams";
import { TableComponent } from "features/tableComponent";


export const TablePage = () => {
  return (
    <LayoutWrapper>
        <RecentNotes className="hidden lg:inline" />
      <Main>
        <div className="flex gap-4 flex-col px-2 py-2">
         <TableComponent/>
        </div>
      </Main>
      <SIPUsers className="hidden lg:inline-block" />
    </LayoutWrapper>
  );
};

export default TablePage;
