import { GET_ALL_BUSINESS, GET_BUSINESS_LIST } from "./businessReducer";
import { Dispatch } from "react";
import API, { storeCommentFile } from "../../api";
// import { API, graphqlOperation } from "aws-amplify";
import store from "../../store";
import { extractUsername } from "../../utils/extractUsername";
import { SET_LOADING } from "../home/homeReducer";
import toast from "react-hot-toast";

const errorResponse = () => {
  toast.error("Error: Failed to fetch business details.");
};

const successResponse = (name: string) => (dispatch: Dispatch<any>) => {
  toast.success(`Success: ${name} details fetched`);
};
const setBusinessInfo = (business: any) => {
  return {
    type: GET_ALL_BUSINESS,
    business_info: business,
  };
};

export const getAllBusinessList =
  (isSales: any) => async (dispatch: Dispatch<any>) => {
    try {
      const response = await API.get(
        `/listBusiness${isSales ? "?type=PROSPECT" : ""}`
      );
      dispatch({ type: GET_BUSINESS_LIST, businessList: response.data });
    } catch (e: any) {
      toast.error(e);
    }
  };
export const updateBusinessAddress =
  (businessUUID: string, value: any) =>
  async (dispatch: Dispatch<any>) => {
    try {
      const rowData = {
        ROWID: businessUUID,
        address: value.address,
        city: value.city,
        state: value.state,
        zipcode: value.zipcode,
      };
      const updateBusinessRes: any = await API.post(
        "/business/update",
        rowData
      );
      if (!updateBusinessRes.data.id) {
       toast.error("Error: Failed to update business address.");
      }
     toast.success("Success: Business Address Updated.");
      // dispatch(getBusinessInfo(businessUUID, navigate));
    } catch (e) {
      // console.log(dispatch);
      console.log(e);
      // dispatch(errorResponse());
    }
  };

export const addNewBusiness =
  (values: any) => async (dispatch: Dispatch<any>) => {
    try {
      const rowData = {
        name: values.name,
        address: values.address,
        city: values.city,
        state: values.state,
        zipcode: values.zipcode,
        phone: values.phone,
      };
      const addBusinessRes: any = await API.post(
        "/business/newprospect",
        rowData
      );
      if (!addBusinessRes.data.id) {
        dispatch(errorResponse());
      }
      dispatch(successResponse("New Business Added."));
    } catch (e) {
      // console.log(dispatch);
      // console.log(e);
      dispatch(errorResponse());
    }
  };

export const getBusinessInfo =
  (businessUUID: string | undefined, navigate: any) =>
  async (dispatch: Dispatch<any>) => {
    try {
      const res: any = await API.get(`/business/${businessUUID}`, {
        params: {
          page: store.getState().homeReducer.searchFilter.page,
          channel: store.getState().authUserReducer.isSalesUser ? "SALES" : "",
        },
      });
      // console.log("res", res);
      if (!res.data.id) {
        console.log("no business id ");
        navigate("/app/");
        toast.error("Error: Invalid Business Information.");
      } else {
        dispatch(setBusinessInfo(res.data));
      }
    } catch (err) {
      toast.error("Error: Failed to fetch business details.");
    }
    dispatch({ type: SET_LOADING, loading: false });
  };

export const createBusinessMeta =
  (value: any) => async (dispatch: Dispatch<any>) => {
    try {
      console.log("action to submitted data");
      console.log(value);
      const businessMeta: any = await API.post("/businessmeta/create", {
        businessID: value.businessUUID,
        type: value.type,
        title: value.label,
        content: value.value,
      });
      if (!(businessMeta.data && businessMeta.data.id)) {
       toast.error("Error: Failed to create business meta.");
      }
      // dispatch(getBusinessInfo(value.businessUUID, navigate));
     toast.success("Success: Business Meta Created.");
    } catch (e) {
      // console.log(dispatch);
      console.log(e);
      // dispatch(errorResponse());
    }
  };

export const createContactByBusiness =
  (value: any, navigate: any) => async (dispatch: Dispatch<any>) => {
    try {
      console.log("action to submitted data");
      console.log(value);
      const businessContact: any = await API.post("/contact/create", {
        businessID: value.businessUUID,
        name: value.person,
        phone: value.phone,
        type: value.type,
      });
      console.log(businessContact);
      if (!(businessContact.data && businessContact.data.id)) {
        toast.error("Error: Failed to create business contact.");
      }
      toast.success("Success: Business Contact Created.");
      dispatch(getBusinessInfo(value.businessUUID, navigate));
    } catch (e) {
      console.log(e);
    }
  };

export const deleteContact =
  (value: any, navigate: any) => async (dispatch: Dispatch<any>) => {
    const business_info = store.getState().businessReducer.business_info;
    try {
      console.log("action to submitted data");
      console.log(value);
      const deletedContact: any = await API.post("/contact/delete", {
        ROWID: value.id,
      });
      console.log(deletedContact);
      if (!(deletedContact.data && deletedContact.data.id)) {
        toast.error("Error: Failed to delete business contact.")
      }
      toast.success("Success: Business Contact Deleted.");
      // dispatch(getBusinessInfo(business_info.id, navigate));
    } catch (e) {
      console.log(e);
      toast.error("Error: Failed to delete business contact.");

    }
  };

export const updateBusinessMeta =
  (value: any, navigate: any) => async (dispatch: Dispatch<any>) => {
    const business_info = store.getState().businessReducer.business_info;
    try {
      console.log("action to submitted data");
      console.log(value);
      const businessMeta: any = await API.post("/businessmeta/update", {
        ROWID: value.businessMetaUUID,
        content: value.content,
        title: value.title,
      });
      console.log(businessMeta);
      if (!(businessMeta.data && businessMeta.data.id)) {
        dispatch(errorResponse());
      }
      dispatch(successResponse("Business Contact Created"));
      dispatch(getBusinessInfo(business_info.id, navigate));
    } catch (e) {
      console.log(dispatch);
      console.log(e);
      dispatch(errorResponse());
    }
  };

export const deleteBusinessMeta =
  (value: any) => async (dispatch: Dispatch<any>) => {
    try {
      console.log(value);
      const businessMeta: any = await API.post("/businessmeta/delete", {
        ROWID: value.id,
      });
      console.log(businessMeta);
      if (!(businessMeta.data && businessMeta.data.id)) {
        toast.error("Error: Failed to delete business meta.");
      }
     toast.success("Success: Business Meta Deleted.");
      // dispatch(getBusinessInfo(business_info.id, navigate));
    } catch (e) {
      console.log(e);
    }
  };

export const sendSms = async (bodyToSms: any) => {
  try {
    const sendSmsRes: any = await API.post("/sendsms", bodyToSms);
    return sendSmsRes.data ? true : false;
  } catch {
    return false;
  }
};
export const sendEmail = async (bodyToEmail: any) => {
  try {
    const sendEmailRes: any = await API.post("email/sendemail", bodyToEmail);
    return sendEmailRes.data ? true : false;
  } catch {
    return false;
  }
};

const storeNoteViaBusiness =
  (type: any, values: any, contact: any, attachments: any) =>
  async (dispatch: Dispatch<any>) => {
    const user = store.getState().authUserReducer.currentUser;
    const isSalesUser = store.getState().authUserReducer.isSalesUser;
    const note_content = `${
      `${user.first_name} ${user.last_name}` ||
      `@${extractUsername(user.email)}`
    } has sent the following sms:<br> ${values.message}`;
    const storeNote: any = await API.post("/notes/create", {
      businessID: values.businessUUID,
      contactID: contact.id,
      userID: user.id,
      type: type,
      status: "OPEN",
      content: note_content,
      channel: isSalesUser ? "SALES" : undefined,
    });
    if (!(storeNote.data && storeNote.data.id)) {
      dispatch(successResponse(`${type} Sent but failed to create note.`));
    }
    if (attachments?.length) {
      API.post("/attachment/updatebulk", {
        rowData: attachments?.map((x: any) => ({
          ...x,
          noteID: storeNote?.data?.id,
        })),
      });
    }
    dispatch(successResponse(`${type} Sent & Note Created`));
  };

const storeAttachments = async (objs: any, comment_id: string) => {
  if (!objs.length) return;
  const res = await storeCommentFile(objs, comment_id);
  return res;
};

export const sendSmsViaBusiness =
  (values: any, contact: any) => async (dispatch: Dispatch<any>) => {
    console.log("values", values,contact)
    try {
      let attachmentRes;
      if (values.attachment) {
        attachmentRes = await storeAttachments(values.attachment, "");
      }
      const smsRes = await sendSms({
        sms_to: contact?.phone,
        sms_body: values.message,
        sms_from: values.smsFrom,
        attachments: attachmentRes,
      });
      if (smsRes) {
        dispatch(storeNoteViaBusiness("SMS", values, contact, attachmentRes));
      } else {
        console.log("smsRes", smsRes)
      }
    } catch (e) {
      console.log(e)
    }
  };

export const sendEmailViaBusiness =
  (values: any, contact: any) => async (dispatch: Dispatch<any>) => {
    try {
      let attachmentRes;
      if (values.attachment) {
        attachmentRes = await storeAttachments(values.attachment, "");
      }
      const emailRes = await sendEmail({
        To: contact.email,
        Content: values.message,
        Subject: values.noteId ? "Re:ticket number:" + values.noteId : "",
        attachments: attachmentRes,
      });
      if (emailRes) {
        dispatch(storeNoteViaBusiness("Email", values, contact, attachmentRes));
      } else {
        toast.error("Error: Email sent failed");
      }
    } catch (e) {
      toast.error("Error: Email sent failed");
    }
  };