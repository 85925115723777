const url = window.location.hostname;
const port = window.location.port;
const protocol = window.location.protocol;

export const PUBLIC_URL = `${protocol}//${url}:${port}`;
export const salesDomain = url.includes("nurture.growthzilla.com");
// export const isSalesUser = false;

// export const PUBLIC_URL = process.env.NODE_ENV == "development" ? "http://localhost:3000" : `${protocol}//${url}`;

export const PUSHER_KEY =
  process.env.NODE_ENV === "development" ||
  url.includes("growthzilla-notes-759987269.development.catalystserverless.com")
    ? "243ee754086459b97831"
    : "6ad0b2720ac507ff89fe";

const DEBUG = process.env.NODE_ENV === "development"; // set to false to disable debugging

if (!DEBUG) {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.log = function () {};
}
