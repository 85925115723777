import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, TypedUseSelectorHook, useSelector } from "react-redux";
import authUserReducer from "./auth_user/authUserReducer";
import noteReducer from "./notes/noteReducer";
import homeReducer from "./home/homeReducer";
import ticketReducer from "./home/ticketSlice";
import tabsReducer from "./tab/tabSlice";
import businessReducer from "./business/businessReducer";

const store = configureStore({
  reducer: {
    authUserReducer: authUserReducer,
    noteReducer: noteReducer,
    homeReducer: homeReducer,
    businessReducer: businessReducer,
    tabsReducer: tabsReducer,
    ticketReducer: ticketReducer,
  },
});
export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
