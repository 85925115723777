import moment from "moment-timezone";

export const localToPST = (datestr: string) => {
  const date = new Date(datestr);
  const timezoneOffsetInMinutes = date.getTimezoneOffset();
  // Convert the timezone offset into milliseconds
  const timezoneOffsetInMillis = timezoneOffsetInMinutes * 60000;

  // Convert the localtime to UTC by subtracting the timezone offset
  const utcDate = new Date(date.getTime() - timezoneOffsetInMillis);

  // Convert the UTC time to PST by adding the PST timezone offset
  const pstOffsetInMillis = 480 * 60000;
  const pstDate = new Date(utcDate.getTime() - pstOffsetInMillis);

  return pstDate;
};

export const PSTtoLocal = (datestr: string | Date) => {
  const dateStr = moment(datestr, "YYYY-MM-DD hh:mm:ss").format("llll");
  const isDST = moment.tz(dateStr, "llll", "America/Los_Angeles").isDST();
  const date = new Date(moment(datestr, "YYYY-MM-DD hh-mm-ss").toDate());
  const localDate = date.setMinutes(
    date.getMinutes() + (isDST ? 420 : 480) - date.getTimezoneOffset()
  );
  return new Date(localDate);
};

// export const PSTtoLocal = (datestr: string) => {
//   const dateStr = moment(datestr, "YYYY-MM-DD hh:mm:ss").format("llll");
//   const isDST = moment.tz(dateStr, "America/Los_Angeles").isDST();
//   const date = new Date(moment(datestr, "YYYY-MM-DD hh-mm-ss").toDate());
//   const localDate = date.setMinutes(
//     date.getMinutes() + (isDST ? 420 : 480) - date.getTimezoneOffset()
//   );
//   return new Date(localDate);
// };

function getTimeDifference(givenTimeStr: string) {
  const givenTime = moment(givenTimeStr, "YYYY-MM-DD HH:mm:ss.SSS");
  const currentTime = moment();
  const duration = moment.duration(currentTime.diff(givenTime));
  const minutes = Math.round(duration.asMinutes());

  if (minutes < 60) {
    return `${minutes} Min`;
  } else if (minutes < 1440) {
    return `${Math.floor(minutes / 60)} hrs`;
  } else {
    return `${Math.floor(minutes / 1440)} days`;
  }
}

export default getTimeDifference;
