import { salesDomain } from "../../constants/constant";
import { extractUsername } from "../../utils/extractUsername";

export const GET_AUTH_USER = "GET_AUTH_USER";

export const GET_SIP_USERS = "GET_SIP_USERS";
export const GET_USER_STATUS = "GET_USER_STATUS";
export const USER_STATUS_UPDATE = "USER_STATUS_UPDATE";
export const USER_STATUS_RESET = "USER_STATUS_RESET";
export const SET_AUTH_LOADING = "SET_AUTH_LOADING";
export const GET_CURRENT_USER_STATUS = "GET_CURRENT_USER_STATUS";

interface AuthUserInterface {
  user_role: any;
  currentUser: any;
  currentUserState: any;
  sipUsers: any;
  userDetails: any;
  isSalesUser: boolean;
  authLoading: boolean;
}

const initialState: AuthUserInterface = {
  user_role: "",
  currentUser: {},
  currentUserState: {},
  sipUsers: [],
  userDetails: [],
  isSalesUser: salesDomain,
  authLoading: false,
};

const authUserReducer = (state = initialState, action: any) => {
  const {
    type,
    user_role,
    currentUser,
    currentUserState,
    sipUsers,
    userDetails,
    status,
    username,
    loading,
  } = action;
  switch (type) {
    case GET_AUTH_USER:
      return {
        ...state,
        user_role: user_role,
        currentUser: currentUser,
        isSalesUser: salesDomain || currentUser?.type === "SALES",
        authLoading: false,
      };
    case GET_SIP_USERS:
      return {
        ...state,
        sipUsers,
      };
    case GET_USER_STATUS:
      return {
        ...state,
        userDetails,
      };
    case GET_CURRENT_USER_STATUS:
      return {
        ...state,
        currentUserState,
      };
    case USER_STATUS_UPDATE:
      return {
        ...state,
        userDetails: state?.userDetails?.find(
          (x: any) => extractUsername(x.email) === username
        )
          ? state.userDetails.map((x: any) => {
              if (extractUsername(x.email) === username)
                return { ...x, status };
              else return x;
            })
          : [
              ...(state?.userDetails || []),
              { email: `${username}@growthzilla.com`, status },
            ],
      };
    case SET_AUTH_LOADING:
      return {
        ...state,
        authLoading: loading,
      };
    default:
      return state;
  }
};

export default authUserReducer;
