import API from "../../api";
import store from "../";
import toast from "react-hot-toast";

const makeACall = async (values: any, phone: any, isSalesUser: any) => {
  const bodyToCall = {
    call_to: values.contact_phone,
    call_from: phone,
    sales: isSalesUser,
  };
  try {
    const callResponse = await API.post("/outcall", bodyToCall);
    console.log("callresponse",callResponse);
    return callResponse.data ? true : false;
  } catch(e:any) {
    toast.error(`Error:${e?.response?.data}`);
    return false;
  }
};

const makeComment =
  async (value: any, user: any) => {
    const createCommentData = {
      content: value.content,
      noteID: value.noteID,
      userID: user.id,
      type: "INFO",
    };

    const comment: any = await API.post(
      "/notes/createcomment",
      createCommentData
    );
    return comment;
  };

const makeNote =
  async (value: any, user: any, type = "CALL") => {
    const isSalesUser = store.getState().authUserReducer.isSalesUser;
    const noteRowData = {
      businessID: value.businessID,
      contactID: value.contactID,
      content: value.content,
      type: type,
      userID: user.id,
      status: "OPEN",
      channel: isSalesUser ? "SALES" : undefined,
    };
    try{
      const newNote: any = await API.post("/notes/create", noteRowData);
      const noteID = newNote.data && newNote.data.id;
      console.log(noteID);
    }catch(e){
      console.log(e);
    }
   
    // dispatch({ type: NOTE_ADDED });
  };

export const handleCallAction =
  async (values: any) => {
    const user = store.getState().authUserReducer.currentUser;
    const isSalesUser = store.getState().authUserReducer.isSalesUser;
    const isNote = values.noteID ?? false;
    try{
      const makeACallResponse = await makeACall(values, user.phone, isSalesUser);
      if (makeACallResponse) {
        if (isNote) {
          console.log("going to create comment ");
           makeComment(values, user);
        } else {
          console.log("going to create note ");
          makeNote(values, user, "CALL_OUT");
        }
      } else {
       console.log("Error:Failed to make a call.");
      }
      return makeACallResponse;
    }catch(e){
     console.log(e);
    }
};
