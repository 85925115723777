import { createSlice } from "@reduxjs/toolkit";

interface ticketSortingState {
  CREATEDTIME: boolean;
}

const initialState: ticketSortingState = { CREATEDTIME: false };

const ticketSortingSlice = createSlice({
  name: "ticketSorting",
  initialState: initialState,
  reducers: {
    toggleSortByCreatedTime: (state) => {
      state.CREATEDTIME = !state.CREATEDTIME;
    },
  },
});

export const { toggleSortByCreatedTime } = ticketSortingSlice.actions;

export default ticketSortingSlice.reducer;
