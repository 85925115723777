export const SET_NOTE = "SET_NOTE";
export const RESET_NOTES = "RESET_NOTES";
export const CLEAR_NOTE = "CLEAR_NOTE";

interface NoteReducerInterface {
  notes: any;
  updated: boolean;
}

const initialState: NoteReducerInterface = {
  notes: {},
  updated: true,
};

const noteReducer = (state = initialState, action: any) => {
  const { type, note, noteID } = action;
  switch (type) {
    case SET_NOTE:
      const tem: any = {};
      tem[`${note.id}`] = note;
      return { ...state, notes: { ...state.notes, ...tem } };
    case CLEAR_NOTE:
      const temp: any = {};
      temp[`${noteID}`] = undefined;
      return {
        ...state,
        notes: { ...state.notes, ...temp },
        updated: !state.updated,
      };
    case RESET_NOTES:
      return { ...state, notes: {} };
    default:
      return state;
  }
};

export default noteReducer;
