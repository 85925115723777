import Modal from "components/modal";
import { useFormik } from "formik";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { sendSmsViaBusiness } from "store/business/businessAction";
import { useAppDispatch, useAppSelector } from "store/index";

interface callModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  noteID?: string;
  contact?: any;
  businessName: string;
  businessID?: string;
}

const SMSModal = ({
  isOpen,
  onClose,
  title,
  noteID,
  contact,
  businessName,
  businessID,

  ...rest
}: callModalProps) => {
  const { currentUser, isSalesUser } = useAppSelector(
    (state) => state.authUserReducer
  );
  const formik = useFormik({
    initialValues: {
      message: "",
      businessUUID: businessID,
      attachment: null,
    },
    onSubmit: async (values, { resetForm }) => {
      toast.promise(
        dispatch(
          sendSmsViaBusiness(
            { ...values, smsFrom: isSalesUser ? currentUser?.phone : "" },
            { phone: contact }
          )
        ),
        {
          loading: "Sending SMS",
          success: () => {
            resetForm();
            onClose();
            return "SMS sent successfully";
          },
          error: () => {
            return "Something went wrong";
          },
        }
      );
    },
  });
  const dispatch = useAppDispatch();
  return (
    <Modal isOpen={isOpen} title={title} onClose={onClose} {...rest}>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2 px-6">
          <div className="flex gap-1 flex-col">
            <label className="text-[14px] font-medium" htmlFor="key">
              SMS Content
            </label>
            <textarea
              className="py-1 px-2 border rounded-sm focus:outline-blue-500/50"
              id="key"
              onChange={(e) => formik.setFieldValue("message", e.target.value)}
              rows={3}
            />
          </div>
          <div className="flex gap-1 flex-col">
            <label className="text-[14px] font-medium" htmlFor="value">
              Attachments
            </label>
            <input
              className="border rounded-sm focus:outline-blue-500/50 file:border-none file:py-2"
              id="value"
              type="file"
            />
          </div>
          <div className="flex gap-6 mt-4 w-full justify-center">
            <button
              onClick={onClose}
              className="min-w-[64px] w-full rounded-md font-medium px-2 py-1 border border-purple-950  text-purple-950 "
            >
              Cancel
            </button>
            <button
              onClick={() => formik.handleSubmit()}
              className="min-w-[64px] w-full rounded-md font-medium px-2 py-1  bg-purple-950  text-white "
            >
              Send SMS
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SMSModal;
