import { X, Paperclip } from "lucide-react";
import React, { ChangeEvent, useState } from "react";
import SwitchComponent from "../components/switch";
import { ITicket } from "../../../constants/Interfaces/notesInterface";
import toast from "react-hot-toast";
import { useAppDispatch } from "../../../store";
import { storeComment } from "../../../store/notes/comment_action";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useFormik } from "formik";
import { NoteStatus } from "constants/noteStatus";

interface FileData {
  name: string;
  file: File;
}

const NoteAddComment = ({ notes }: { notes: ITicket }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [replySMSorEmail, setReplySMSorEmail] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<FileData[]>([]);
  const dispatch = useAppDispatch();
  const formik = useFormik({
    initialValues: {
      content: "",
      attachments: [],
    },
    onSubmit: async (values, { resetForm }) => {
      if (!values.content) {
        toast.error("Error: Comment cannot be empty");
      } else {
        try {
          console.log(values);
          await storeComment({
            ...values,
            noteStatus: notes?.status,
            noteId: notes?.id,
            replySMSorEmail: replySMSorEmail,
            contactNumber: notes?.Contact?.phone,
            emailId: notes?.Contact?.email,
            noteType: notes?.type,
            noteAssigned: notes?.userID,
          });
        } catch (e) {
          toast.error("Error: Adding comment failed");
        } finally {
          resetForm();
          setSelectedFiles([]);
          setReplySMSorEmail(false);
        }
      }
    },
  });

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const files: FileList | null = event.target.files;
    if (files) {
      const filesArray: FileData[] = Array.from(files).map((file) => ({
        name: file.name,
        file: file,
      }));
      setSelectedFiles([...selectedFiles, ...filesArray]);
      console.log(files);
      formik.setFieldValue("attachments", files);
    }
  };

  const handleFileRemove = (fileName: string) => {
    const newFiles = selectedFiles.filter((file) => file.name !== fileName);
    setSelectedFiles(newFiles);
    formik.setFieldValue("attachments", newFiles);
  };
  return (
    <div className="flex border-t items-center border-[#f2f4f7] gap-4 px-4 py-2">
      {/* comment input area */}

      <div className="w-full flex flex-col gap-4 border px-4 rounded-md  border-[#E6EBEF]">
        <div className="flex gap-2">
          <textarea
            className="w-full py-2 focus:outline-none"
            placeholder="Add a comment"
            value={formik.values.content}
            onChange={(e) => formik.setFieldValue("content", e.target.value)}
            rows={3}
          />
          {/* <button className="text-[#828282]">
            <X className="text-[#828282]" size={16} />
          </button> */}
        </div>
        {!!selectedFiles?.length && (
          <div className="flex gap-2 py-2 border-t flex-wrap">
            {selectedFiles?.map((file: any) => (
              <div
                className=" w-fit items-center justify-between px-2 flex gap-2 border rounded-full"
                key={file?.name}
              >
                <p className=" text-[#9f9f9f] text-[12px] italic max-w-[64px] line-clamp-1 text-ellipsis">
                  {file?.name}
                </p>
                <button
                  className="p-[1px] bg-slate-200 rounded-full text-[#9f9f9f]"
                  onClick={() => handleFileRemove(file.name)}
                >
                  <X size={12} />
                </button>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* action buttons */}
      <div className="flex flex-col gap-4">
        {Boolean(notes?.Contact?.email || notes?.Contact?.phone) && (
          <div className="flex gap-2 items-center">
            <p className="text-[14px] text-[#828282]">Comment</p>
            <SwitchComponent
              onChange={() => {
                setReplySMSorEmail(!replySMSorEmail);
                formik.setFieldValue("replySMSorEmail", !replySMSorEmail);
              }}
              isChecked={replySMSorEmail}
            />
            <p className="text-[14px] text-[#828282]">
              {notes?.type === "EMAIL" ? `Email` : `SMS`}
            </p>
          </div>
        )}

        <div className="flex gap-2">
          <button
            onClick={() => formik.handleSubmit()}
            // type="submit"
            className="bg-primary text-white px-4 py-2 rounded-md"
          >
            {replySMSorEmail ? "Send" : "Save"}
          </button>
          <div className="flex">
            <label
              htmlFor={`attachments-[${notes?.id}]`}
              className="bg-white cursor-pointer text-[#2a2a2a] border border-[#E6EBEF] px-4 py-2 rounded-md"
            >
              <Paperclip size={20} />
            </label>
            <input
              id={`attachments-[${notes?.id}]`}
              onChange={handleFileSelect}
              className="hidden"
              type="file"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoteAddComment;
